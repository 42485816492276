import React, { useContext } from 'react'
import DeltaHomeContext from '../Context/DeltaHomeContext'
import SearchFormHeader from './SearchFormHeader'
// import ChannelPartnerKnowledgeCapsule from './ChannelPartnerKnowledgeCapsule'
import ChannelPartnerHeadSearchResults from './ChannelPartnerHeadSearchResults'
import "../css/ChannelPartner/NewCSS/owl.carousel.min.css";
import "../css/ChannelPartner/NewCSS/owl.theme.default.min.css";
import "../css/ChannelPartner/NewCSS/animate.css";
import "../css/ChannelPartner/NewCSS/style2.css";
import "../css/ChannelPartner/NewCSS/responsive.css";
// import ChannelPartnerHeadSearchWrapper from './ChannelPartner/ChannelPartnerHeadSearchWrapper'

const DocumentSearchResult = () => {
  const deltaHomeContext = useContext(DeltaHomeContext)
  const resetchannel = ()=>{ 
    deltaHomeContext.useChannelPrompt(true);  
  }
  const isChannelPartner = localStorage.getItem("isChannelPartner")
  return (  
    <>    
    <>
      {isChannelPartner === "1" && window.location.pathname === "/search" && !deltaHomeContext.showOutageTemplate? 
      <>
      <SearchFormHeader
          onReset={resetchannel}
          onSubmit={deltaHomeContext.onSearchFormSubmit}
          searchText={deltaHomeContext.searchTerm}
          activeFilter={deltaHomeContext.activeFilter}
          CustomerIDValue={deltaHomeContext.fineSearchValue}
          onCustomerSubmit={deltaHomeContext.onCustomerSearchFormSubmit}
          setActiveFilterAndValue={deltaHomeContext.setActiveFilter}
          ResetCustomerCapsule={deltaHomeContext.ResetCustomerCapsule}
          ResetKnowledgeAndRefCapsule={
            deltaHomeContext.ResetKnowledgeAndRefCapsule
          }
          resetRefinedSearch={deltaHomeContext.resetRefinedSearch}
          ref={deltaHomeContext.ClearSearch}
          // history={this.props.history}
          isClose={deltaHomeContext.closeSurvey}
          triggeredRefineSearch = {deltaHomeContext.triggeredRefineSearch}
          // showFeedback={this.showFeedback}
        />
      <ChannelPartnerHeadSearchResults
      selectedFilters={deltaHomeContext.SelectedFilters}
          docs={deltaHomeContext.serviceData}
          hpse={deltaHomeContext.hpseData}
          Presentations={deltaHomeContext.PresentationsData}
          SelectedKnowledgeCategory={
            deltaHomeContext.setselectedKnowledgeCategory
          }
          GuidesData={deltaHomeContext.GuidesData}
          acceleratorData = {deltaHomeContext.acceleratorData}
          ServicesData={deltaHomeContext.ServicesData}
          SKUData={deltaHomeContext.SKUData}
          searchTerm={deltaHomeContext.ChannelPartnerSearchTerm}
          //MultimediaData={this.state.MultimediaData}
          MultimediaData={deltaHomeContext.MultiMediumData}
          POCList={deltaHomeContext.POCList}
          TrainingsData={deltaHomeContext.TrainingsData}
          filters={deltaHomeContext.filters}
          relevancyScore={deltaHomeContext.maxScore}
          isClose={deltaHomeContext.closeSurvey}
          checkSelectedCategory={deltaHomeContext.checkSelectedCategory}
          filterData={deltaHomeContext.filterData}
          >

      </ChannelPartnerHeadSearchResults>

      </> : 
      
      <></>}
    </>
  
  </>


    // <div>DocumentSearchResult</div>
  )
}

export default DocumentSearchResult