import React, { useEffect, useState } from 'react'
import hpeLogo from "../img/ChannelPartner/hpe_logo.png";
import Cookies from "js-cookie";
import { Accordion, Card, CloseButton, Modal, Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TrackingService from "../TrackingService";
import axios from "axios";
import Avatar from 'react-avatar';
import Sidebar from './Admin/SideBar';

  const trackingService = new TrackingService();
const ChannelPartnerHeader = ({onSubmit,setActiveFilter,
    onCustomerSearchFormSubmit,
    onSearchFormSubmit, 
    user,
    dispatch,}) => {
      const signal = axios.CancelToken.source();
      const navigate = useNavigate();

      const [userName , setUserName] = useState("");
      const [isSidebarOpen,setIsSidebarOpen] = useState(false)
      const adminUsers = ["nitin-kaveriappa.udiyanda-muthanna@hpe.com","arpan.biswas@hpe.com","harimohan@hpe.com","ahalya.or-ext@hpe.com","shivani.chaurasia@hpe.com","pratiksha.shashikant-pawar@hpe.com"]

      useEffect(() => {
        if(localStorage.getItem("name")) {
          const str = localStorage.getItem("name");
          let username = (str.replace(/[^a-zA-Z ]/g, " "));
          username = username.split(" ")[0]
          setUserName(username)
        }
      }, [localStorage.getItem("name")]);


      const handleOpenSideBar =()=>{
        setIsSidebarOpen(true)
    
      }
    
      const handleCloseSideBar =()=>{
        setIsSidebarOpen(false)
    
      }



     
  return (
    <>
    {window.location.pathname ==="/search1"?(
      <header>
     <nav class="navbar navbar-expand-lg">
    <div className="d-flex flex-row align-items-center ps-4 fs-3 w-100">
      <Navbar.Brand href="/">
        <img
          src={hpeLogo}
          width="100"
          height="50"
          alt=""
        />{" "}
      </Navbar.Brand>

      <div class="collapse navbar-collapse" id="main_nav">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a
                class="nav-link text-dark channelPartner-fontWeight"
                // href="#"
              >
                Welcome <span className='text-capitalize'>{userName}</span>, to the Digital Library !
              </a>
            </li>
          </ul>
          </div>
    </div>
    {/* </Container> */}
    <div className="d-flex justify-content-center align-items-center pe-3">
      
      <Nav className="ms-3">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <Tooltip className=" homepage-profile-tooltip">
              <>
                <div>
                  {Cookies.get("empnumber") !== undefined
                    ? Cookies.get("name")
                    : "User"}
                </div>
              </>
            </Tooltip>
          }
        >
          <div className="channelPartner-avtar">
            <Avatar
              // onClick={() => handleClickUserProfile()}
              className="homepage-profile-avatar"
              color={Avatar.getRandomColor("sitebase", [
                "red",
                "green",
                "blue",
              ])}
              name={
                Cookies.get("empnumber") !== undefined
                  ? Cookies.get("name")
                  : "User"
              }
              title=""
              size="35"
              round
            />
          </div>
        </OverlayTrigger>
      </Nav>
    </div>
  </nav>
  </header>):( <>
  <Navbar
    bg="white"
    variant="light"
    className="navbar-centered"
  >
    <div className="d-flex flex-row align-items-center ps-4 fs-3 w-100">
      <Navbar.Brand href="/">
        <img
          src={hpeLogo}
          width="100"
          height="50"
          alt=""
        />{" "}
      </Navbar.Brand>

      <div className="text-dark channelPartner-fontWeight">
          <h5 className='welcomeBanner'>
          Hello <span className='text-capitalize'>{userName}</span>, Welcome to Knowledge management Center
          </h5>
      </div>
    </div>
    <div className="d-flex justify-content-center align-items-center pe-3">
      <Nav className="ms-3">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="bottom"
          overlay={
            <Tooltip className=" homepage-profile-tooltip">
              <>
                <div>
                  {Cookies.get("empnumber") !== undefined
                    ? Cookies.get("name")
                    : "User"}
                </div>
              </>
            </Tooltip>
          }
          
        >
          <div className='cp_header-profile'>
            <div className='col'>
          {adminUsers.includes(localStorage.getItem("mail")) &&
              <i class="fa-xs fa fa-cog channelPartner-facog" onClick={handleOpenSideBar}></i>
  }
  </div>
  <div className='col'>
            <Avatar
              // onClick={() => handleClickUserProfile()}
              className="homepage-profile-avatar"
              color={Avatar.getRandomColor("sitebase", [
                "red",
                "green",
                "blue",
              ])}
              name={
                Cookies.get("empnumber") !== undefined
                  ? Cookies.get("name")
                  : "User"
              }
              title=""
              size="35"
              round
            />
            </div>
          </div>
        </OverlayTrigger>
      </Nav>
    </div>
  </Navbar>
</>)}
   

<Sidebar
       show={isSidebarOpen}
       handleClose={handleCloseSideBar}
       />
 
</>
  )
}

export default ChannelPartnerHeader
