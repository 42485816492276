import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SendNotificationModal from './SendNotificationModal';

const Sidebar = ({show,handleClose}) =>  {
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);

  const handleCloseSendNotificationModal = () => setIsSendNotificationModalOpen(false);
  const handleOpenSendNotificationModal = () => setIsSendNotificationModalOpen(true);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} className="send-notification_main">
        <Offcanvas.Header  className="send-notification_header">
          <Offcanvas.Title className="send-notification_title" >Admin Menu</Offcanvas.Title>
          <button type="button" class="btn-close send-notification_title" aria-label="Close"></button>
        </Offcanvas.Header>
        <Offcanvas.Body className="send-notification_body">
         <ul className="send-notification_ul">
            <li onClick={handleOpenSendNotificationModal} className="send-notification_li">
                <button type="button" class="btn btn-success send-notification-btn">Send Notification</button>
            </li>
         </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {isSendNotificationModalOpen && <SendNotificationModal
      show={isSendNotificationModalOpen}
      handleCloseSendNotificationModal={handleCloseSendNotificationModal}
      />}
    </>
  );
}

export default Sidebar;



