import React, { Component } from "react";
import "bootstrap";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import URLConfig from "../URLConfig";
import logo from "../img/loading-icon-animated.gif";
import TrackingService from "../TrackingService";
import UseFulLinks from "./UseFullLinks";
// import Tips from "./Tips";
import { UserContext } from "../Context/UserContext";
import KMNuggets from "./KMNuggets";
import Maintainance from "./Maintenance/UnscheduledMaintenance";
//import { withRouter } from "react-router";
import Feedback from "./Feedback/Feedback";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
//import HeaderFormRedesign from "./HeaderFormRedesign";
import { CloseButton } from "react-bootstrap";
//import GuestPersona from "./UserProfile/GuestPersona";
//import CustomDialogPopup from "./Common/CustomDialogPopup";
import DeltaHomeContext from "../Context/DeltaHomeContext";
import { Outlet } from "react-router-dom";
import ChannelPartnerHeader from "./ChannelPartnerHeader";
import ChannelPartnerChatBot from "./ChannelPartnerChatBot";
import CustomDialogPopup from "../Common/CustomDialogPopup";
class Home extends React.Component {
  static contextType = DeltaHomeContext;
  constructor(props) {
    super(props);
    window.callbackPursuit = this;
    this.state = {
      hasTouched: false,
      SearchInitiated: false,
      isDeltaUser: true,
      isMovedAlready: false,
      createdFromDate: new Date(),
      createdToDate: new Date(),
      publishedFromDate: new Date(),
      publishedToDate: new Date(),
      lastModifiedFromDate: new Date(),
      lastModifiedToDate: new Date(),
      tooltipshow: true,
      AccountSTID: "",
      selectedCategory: [],
      isTemplateEnable: false,
      showFooter: false,
      invokeGetMappingProfileforRestriction: false,
      peopleCapsuleData: null,
      showDeliveryMixModal: false,
      daysleft: 0,
      showFeatureGuide: false,
      loader: true,
      userRole: Cookies.get("roles"),
      showGuestPersonaModal: false,
      isBotLoaded: false,
    };
    this.boxRef = React.createRef();
  }
  getMappingProfileforRestriction; // };

  componentDidMount = () => {
    this.TrackingService = new TrackingService();
    console.log(" componentDidMount");
    this.setState({
      invokeGetMappingProfileforRestriction: true,
    });
    //this.context.getMappingProfileforRestriction();
    // this.getAllMasterData();
    document.addEventListener(
      "contextmenu",
      (e) => {
        e.preventDefault();
      },
      false
    );
    this.blinkMe();
    this.getRemainingDays();

    if (
      localStorage.getItem("roles") == "Guest" &&
      localStorage.getItem("isChannelPartner") == 0
    ) {
      this.setState({ showGuestPersonaModal: true });
    }

    this.setState({ isBotLoaded: true });
  };

  componentDidUpdate = () => {
    if (
      window.location.pathname === "/" &&
      document.getElementById("SearchAutoSuggestionsPrimary")?.hidden ===
        true &&
      document.getElementById("content").hidden === true
    ) {
      document.getElementById("SearchAutoSuggestionsPrimary").hidden = false;
      document.getElementById("content").hidden = false;
    }
    if (this.state.invokeGetMappingProfileforRestriction) {
      console.log("componentDidUpdate if if");
      // this.context.getMappingProfileforRestriction();
      this.setState({
        invokeGetMappingProfileforRestriction: false,
      });
    }
  };

  hideGuestPersonaModal = () => {
    this.setState({ showGuestPersonaModal: false });
  };
  blinkMe = () => {
    setTimeout(() => {
      this.setState({ tooltipshow: false });
    }, 5000);
    return false;
  };
  getRemainingDays = () => {
    var countDownDate = new Date("Apr 16, 2022 00:00:00").getTime();
    var now = new Date().getTime();
    var timeleft = countDownDate - now;
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    this.setState({ daysleft: days });
  };

  MoveSearchElements = () => {
    this.context.hidePractice();
    this.setState({ SearchInitiated: true, isMovedAlready: true });
    if (document.getElementById("SearchAutoSuggestions"))
      if (
        document.getElementById("SearchAutoSuggestionsPrimary")?.children.length
      ) {
        // document.getElementsById("content")[0].hidden = true;
        // document.getElementsByClassName("main-container")[0].hidden = true;
        // document.getElementsByClassName("header-logo-only-hp")[0].hidden = true;
        if (
          document
            .getElementsByClassName("header-logo-with-anps")[0]
            ?.classList.contains("display-none")
        )
          document
            .getElementsByClassName("header-logo-with-anps")[0]
            .classList.toggle("display-none");
        document.body.classList.toggle("body-grey-background");
        if (document.getElementsByClassName("usefullinks")[0])
          document.getElementsByClassName("usefullinks")[0].hidden = true;
        if (
          document
            .getElementsByClassName("search-base")[0]
            .classList.contains("display-none")
        )
          document
            .getElementsByClassName("search-base")[0]
            .classList.toggle("display-none");
        document.getElementById("searchBtn").hidden = true;
      }
  };

  onDeliveryMixPdfClose = () => {
    this.setState({
      showDeliveryMixModal: !this.state.showDeliveryMixModal,
    });
  };

  archiveActiveValue = (activearchivevalue) => {
    this.setState(
      {
        archiveActive: activearchivevalue,
      },
      () => {}
    );
  };

  showHideFeatureGuide = (e) => {
    e.preventDefault();
    this.setState({
      showFeatureGuide: !this.state.showFeatureGuide,
    });
  };

  render() {
    const Roles = Cookies.get("roles")?.split(",");
    const envData = URLConfig.get_Env(); // get the application environment (UAT/PROD)
    const isChannelPartner = localStorage.getItem("isChannelPartner");

    // test comment
    return (
      <>
        <UserContext.Consumer>
          {([user, dispatch]) => {
            return (
              <>
                {(window.location.pathname === "/search" ||
                  window.location.pathname === "/search1") &&
                isChannelPartner === "1" ? null : isChannelPartner === "1" && this.props.imageMapperLoaded ? (
                  <ChannelPartnerHeader
                    onSubmit={this.context.onSearchFormSubmit}
                    user={user}
                    dispatch={dispatch}
                  />
                ) : (
                  <></>
                  // <HeaderFormRedesign
                  //   user={user}
                  //   dispatch={dispatch}
                  //   SearchInitiated={this.state.SearchInitiated}
                  //   updateRoles={this.context.UpdateRoles}
                  //   filters={this.context.filters}
                  //   SelectedFilters={this.context.SelectedFilters}
                  //   onSubmit={this.context.onSearchFormSubmit}
                  //   hasTouched={this.state.hasTouched}
                  //   searchTerm={this.context.searchTerm}
                  //   toggleDocDepo={this.context.toggleDocDepo}
                  //       UpdateUserCategoryMapping={
                  //         this.context.UpdateUserCategoryMapping
                  //       }
                  //   // showFeedback={this.showFeedback}
                  //   // toggleDocDepoFun={this.toggleDocDepoFun}
                  //   // showDocDepo = {this.showDocDepo}
                  //   // showHideDivProjects = {this.showHideDivProjects}
                  // />
                )}
              </>
            );
          }}
        </UserContext.Consumer>
        {this.context.showOutageTemplate && <Maintainance />}
        <Outlet />

        {isChannelPartner !== "1" &&
          // !this.context.MasterData &&
          this.context.toggleDocDepo &&
          !this.context.showDocAnalysisModule && (
            <div className="text-center">
              <img className="loading-img" src={logo} alt="loading"></img>
            </div>
          )}

        {!this.context.toggleDocDepo && !this.context.showOutageTemplate && (
          <div
            id="maincontainer"
            className="container-fluid bg-imgcss main-container"
          >
            
            
            <div>
              {this.context.showFeedbackform && (
                <Modal
                  show={this.context.showFeedbackform}
                  onHide={this.context.showFeedback}
                  dialogClassName="sow-feedback-modal margin-padding-zero"
                  animation={false}
                >
                  <Modal.Body className="margin-padding-zero">
                    <div className="d-flex justify-content-between align-items-center sow-feedback-header">
                      <div className="custom-feedbacktitle">
                        Knowledge Management Center – Feedback Form
                      </div>
                      <button
                        type="button"
                        translate="no"
                        className="btn-close feedback_modal_close"
                        data-dismiss="modal"
                        onClick={() => {
                          this.context.showFeedback();
                        }}
                      />
                    </div>
                    <div className="sow-feedback-form-wrapper">
                      <Feedback
                        onFeedbackFormClose={this.context.onFeedbackFormClose}
                        closeFeedback={this.context.closeFeedback}
                      />
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {/* {this.state.showGuestPersonaModal && (
                <GuestPersona
                  showGuestPersonaModal={this.state.showGuestPersonaModal}
                  hideGuestPersonaModal={this.hideGuestPersonaModal}
                />
              )} */}
            </div>
          </div>
        )}
        {this.context.toggleDocDepo && this.context.showFeedbackform && (
          <Modal
            show={this.context.showFeedbackform}
            onHide={this.context.showFeedback}
            dialogClassName="deltaFeedbackModal"
            animation={false}
          >
            <Modal.Header>
              <div className="homepage-book-modal_close">
                <CloseButton
                  className="homepage-book-modal_close_btn"
                  onClick={() => this.context.showFeedback()}
                />
              </div>
            </Modal.Header>
            <Modal.Body bsPrefix="document-data-modal">
              <Feedback
                onFeedbackFormClose={this.context.onFeedbackFormClose}
                closeFeedback={this.context.closeFeedback}
              />
            </Modal.Body>
          </Modal>
        )}

        {this.context.isLoading && isChannelPartner !== "1" && (
          <div className="text-center">
            <img className="loading-img" src={logo} alt="loading"></img>
          </div>
        )}
        {/* <Tips /> */}
        {/* {this.context.isRefreshText && !this.context.toggleDocDepo && (
          <div>
            <button className="designbuttton">
              <div className="titlealign">Reset is complete</div>
              <div>
                <b>New Session is created.You can start your search again</b>
                <div>
                  <i className="fas fa-check-circle fa-lg text-success"></i>
                </div>
              </div>
            </button>
          </div>
        )} */}
        {/*  */}
        {/* <footer className="custom-footer"> */}
        {/* </footer> */}

        {this.state.showFooter && (
          <footer
          // Commented this code on 22nd Jan to avoid vertical scroll in delta
          // className={
          //   (!this.state.toggleDocDepo &&
          //     this.state.serviceData != null &&
          //     this.state.POCList != null) ||
          //   this.state.CustomerOpportunityData !== null
          //     ? "footer-after-results"
          //     : ""
          // }
          >
            {
              <>
                {isChannelPartner === "1" &&
                window.location.pathname !== "/" ? null : (
                  <>
                    <div
                      id="usefullinks"
                      className="container-fluid mt-5 col-12 usefullinks "
                    >
                      {/* <span className="text_c" 
         onClick={()=> {
          this.setState({showFooter: this.state.showFooter == true ? false : true})
           }}> */}
                      
                      <UseFulLinks />
                    </div>
                  </>
                )}
                <div
                  className="container-fluid text-center text-md-left"
                >
                  <div className="row">
                    {/* <div className="col-md-4 mt-md-0 mt-3"></div> */}

                    <hr />

                    <div className="col-md-4 mb-md-0 mb-3 mt-2">
                      <p>
                        © Copyright {new Date().getFullYear()} Hewlett Packard
                        Enterprise Pvt. Ltd.
                      </p>
                    </div>

                    
                  </div>
                </div>
              </>
            }
          </footer>
        )}

        {/* {this.state.showFooter && <UseFulLinks />} */}
        {isChannelPartner === "1" && this.props.imageMapperLoaded? (
          <>
            {/* {!this.state.isBotLoaded &&  */}
            <div
              className={
                window.location.pathname.includes("/search")
                  ? "chatbot-container-channel-partner chatbot-container-channel-partner-search"
                  : "chatbot-container-channel-partner"
              }
            >
              <ChannelPartnerChatBot
                loadBot={this.state.isBotLoaded}
              ></ChannelPartnerChatBot>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Modal for no custoemr details*/}
        <Modal
          size="md"
          show={this.context.noCustomerDetails}
          animation={false}
        >
          <ModalHeader>
            <h5 className="modal-title">Alert</h5>
          </ModalHeader>
          <Modal.Body>
            <p>
              {" "}
              Customer Details is not avaiable for the applied filter.<br></br>
              Click on <b>Yes</b> to Continue or click on <b>Reset</b> to reset
              the filter
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-dark"
              onClick={() => {
                this.context.alertResetClicked();
                // this.setState({

                //   noCustomerDetails: false,
                //   doResetFilters: !this.state.doResetFilters,
                // });
              }}
            >
              Reset
            </Button>
            <Button
              className="btn btn-sm btn-success"
              onClick={() => {
                this.context.alertYesClicked();
                // this.setState({
                //   noCustomerDetails: false,
                //   CustomerOpportunityData: [],
                //   AccountID: "",
                // });
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {this.context.confirmationPopup ? (
          <CustomDialogPopup
            confirmationPopup={this.context.confirmationPopup}
            message={this.context.message}
            title={this.context.title}
            buttons={this.context.buttons}
            customCSS={this.context.customCSS}
          />
        ) : null}
      </>
    );
  }
}
//export default withRouter(Home);
export default Home;
