import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import { map, filter, slice, uniqBy } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import logo from "../img/loading-icon-animated.gif";

import AccountNameAutoSuggest from "./AccountNameAutoSuggest";
import URLConfig from "../URLConfig";
import { Modal } from "react-bootstrap";
import KMNuggets from "./KMNuggets";
import DocumentAutoSuggestNew from "./DocumentAutoSuggestNew";
import { CloseButton } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChannelPartnerBook from "../img/ChannelPartner/Books/ChannelPartner.pdf"
import DeltaHomeContext from "../Context/DeltaHomeContext";
import { UserContext } from "../Context/UserContext";

  const CPURL = window.location.origin + "/Delta-new-Web/books/channel-partner-hard-cover/index.html";


class SearchForm extends React.Component {
  static contextType = DeltaHomeContext
  state = {
    searchText: "",
    customerID: "",
    revenueData: null,
    showRefineSearch: false,
    fineSearch: "",
    searchType: "",
    accountNameValue: "",
    searchHistory: [],
    refineSearch: false,
    showVideo: false,
    textSearch: "",
    deltaDownloadURl:
      window.location.origin + "/Delta-web-new/books/Delta/index.html",
    iframeLoaded: false,
    showBooksModal: false,
    bookType: "",
    bookDownloadURL: "",
    carouselPosition: { left: "1315px", top: "715px", height: "240px", width: "410px" },
    // imageMapperLoaded: false,
    slide: "",
    isSearchFormRedesign: false,
  };

  getHPENews = async () => {
    const URL = URLConfig.getHPENewsURL();
    try {
      const res = await axios.get(URL)
      if (res && res.data) {
        // console.log(res.data);
        const sortedData = res.data.sort((a, b) => new Date(b.reported_Date) - new Date(a.reported_Date)); // Sort the data
        // console.log(sortedData);
        this.setState({ slide: sortedData });
      }
    } catch (error) {
      console.log(error);
    }
  }


  fetchSearchHistory = async () => {
    const URL =
      URLConfig.getURL_UserTracking() +
      "/" +
      Cookies.get("empnumber") +
      "/" +
      "Search";
    try {
      const res = await axios.get(URL);
      if (res && res.data) {
        this.setState({ searchHistory: res.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  componentDidMount() {
    console.log("UserContext", UserContext)
    this.setState({ isSearchFormRedesign: true });
  }
  componentDidUpdate(prevProps) {
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({ searchText: this.props.searchText });
    }
    if (
      this.props.activeFilter === "Account Name" &&
      prevProps.CustomerIDValue !== this.props.CustomerIDValue
    ) {
      const showRefineToggleButton = this.props.CustomerIDValue.trim() === "";
      this.setState(
        {
          accountNameValue: this.props.CustomerIDValue,
          fineSearch: this.props.CustomerIDValue,
          searchType: this.props.activeFilter,
          showRefineToggleButton,
          showRefineSearch: true,
        },
        () => this.fireAccountNameSearch(this.props.CustomerIDValue)
      );
    }
  }

  toggleRefineSearch = () => {
    let oldValue = this.state.showRefineSearch;
    this.setState({ showRefineSearch: !oldValue });
  };
  setActiveFilter = (filter) => {
    this.setState({ searchType: filter });
  };
  onFineSearchChange = (textSearch) => {
    // let fineSearch = e.target.value;
    this.setState({ textSearch, customerID: textSearch });
    this.identifySearchType(textSearch);
  };
  isNumber = (str) => {
    var reg = new RegExp("^[0-9]+$");
    return reg.test(str);
  };
  identifySearchType = (fineSearch) => {
    var hasNumber = /\d/;
    if (fineSearch.length > 2) {
      if (this.isNumber(fineSearch) && fineSearch.length < 10) {
        this.setState({ searchType: "ST ID" });
      } else if (fineSearch.toUpperCase().indexOf("PR-") === 0) {
        this.setState({ searchType: "PR ID" });
      } else if (
        (fineSearch.toUpperCase().indexOf("OPP") === 0 ||
          fineSearch.toUpperCase().indexOf("OPE") === 0) && fineSearch.length < 16
      ) {
        this.setState({ searchType: "OPP ID" });
      } else if (
        URLConfig.get_countryCode().indexOf(
          fineSearch.substring(0, 2).toUpperCase()
        ) !== -1 &&
        fineSearch.indexOf("-") === 3 && fineSearch.length === 9
      ) {
        this.setState({ searchType: "WBS ID" });
      } else if (
        (fineSearch.length === 8 || fineSearch.length === 10 || fineSearch.length === 12) &&
        fineSearch.includes("-") === false &&
        !this.isNumber(fineSearch) &&
        hasNumber.test(fineSearch) &&
        fineSearch.includes(" ") == false
      ) {
        this.setState({ searchType: "Serial ID" });
      } else if (
        (fineSearch.length === 10 || fineSearch.length === 12) &&
        fineSearch.includes("-") === false &&
        this.isNumber(fineSearch)
      ) {
        this.setState({ searchType: "Service Agreement Id" });
      }
      else {
        this.setState({ searchType: "General Search" });
      }
    } else {
      this.setState({ searchType: "General Search" });
    }
    if (fineSearch.length === 0) {
      this.setState({ searchType: "", accountNameValue: "" });
    }
  };
  isAlphaNumeric = (str) => {
    var reg = new RegExp(/^[A-Za-z0-9]+$/);
    return reg.test(str);
  };
  fireCustomerCapsuleSearch = () => {

    const { onCustomerSubmit } = this.props;
    const { onSubmit } = this.props;
    // this.props.setActiveFilterAndValue(
    //   this.state.searchType,
    //   this.state.fineSearch
    // );
    //Validations
    if (this.state.searchType && this.state.searchType === "OPP ID") {
      if (this.state.textSearch.length !== 14) {
        toast.error("Please enter valid Opportunity ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "WBS ID") {
      if (this.state.textSearch.length !== 9) {
        toast.error("Please enter valid WBS ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "ST ID") {
      let re = new RegExp("^[0-9]*$");
      if (!this.state.textSearch.match(re)) {
        toast.error("Please enter valid ST ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "PR ID") {
      if (!this.state.textSearch.startsWith("PR-")) {
        toast.error("Please enter valid PR ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "Serial ID") {
      if (this.state.textSearch.trim().length < 12) {
        if (this.state.textSearch.trim().length < 10) {
          if (this.state.textSearch.trim().length < 8) {
            toast.error("Please enter valid Serial Number ID ", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            return;
          }
        }
      }
    } else if (
      this.state.searchType &&
      this.state.searchType === "Account Name"
    ) {
      let re = new RegExp("/^([0-9]|[a-z])+([0-9a-z]+)$/i");
      if (
        !this.state.textSearch.match(re) &&
        this.state.textSearch.length < 10
      ) {
        toast.error("Please enter valid Account Name", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    }

    if (
      this.state.searchType &&
      (this.state.searchType === "OPP ID" || this.state.searchType === "WBS ID")
    ) {
      const Config = URLConfig.GetSearchKeyByOppID(
        this.state.textSearch.trim()
      );
      axios(Config).then((res) => {
        if (res.data && res.data.response) {
          if (this.state.searchText === "")
            this.setState({ searchText: res.data.response });
          onSubmit(this.state.searchText.replace(/[#?&@]/g, " "));
          this.context.navigateDocumentSearchResult()
          // commenting the below code as it makes the search function to execute twice
          // onCustomerSubmit(
          //   this.state.customerID
          //     .trim()
          //     .replace(/[#?&@]/g, " ")
          //     .toUpperCase()
          //     .split(".")[0]
          // );
        } else if (res.data && res.data.error) {
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
      });
    }
    if (this.state.searchType && this.state.searchType === "Serial ID") {
      onCustomerSubmit(this.state.textSearch);
      this.context.navigateDocumentSearchResult()

    } else {
      setTimeout(() => {
        const CustID =
          this.state.searchType === "Account Name"
            ? this.state.accountNameValue
            : this.state.textSearch
              .trim()
              .replace(/[#?&@]/g, " ")
              .toUpperCase()
              .split(".")[0];
        onCustomerSubmit(CustID);


        this.state.searchText &&
          onSubmit(this.state.searchText.replace(/[#?&@]/g, " "));

      }, 1000);

      //this.state.searchText&&this.doSearch(this.state.searchText.replace(/[#?&@]/g, " "));
    }

  };

  fireSearch = async (AutoSuggestSearchText) => {
    if (this.state.searchType == "General Search" || this.state.searchType == "") {
      const { onSubmit } = this.props;
      if (this.state.textSearch.trim() === "") {
        return;
      }
      // Added code on 12Feb as the auto suggestion test wasn't coming properly in search result
      onSubmit(AutoSuggestSearchText);
      this.context.navigateDocumentSearchResult()
    } else {
      await this.props.setActiveFilterAndValue(
        this.state.searchType,
        this.state.textSearch
      );
      // this.setState(()=>{
      //   this.props.setActiveFilterAndValue(
      //     this.state.searchType,
      //     this.state.fineSearch
      //   );
      // })
      this.fireCustomerCapsuleSearch();


    }

  };

  updateAccountNameSearchKey = (accountNameValue) => {
    if (accountNameValue.length != 0) {
      this.setState({ accountNameValue, fineSearch: accountNameValue });
      this.identifySearchType(accountNameValue);
    } else {
      this.setState(
        {
          accountNameValue,
          fineSearch: accountNameValue,
          searchType: "",
          customerID: "",
        },
        () => {
          this.fineSearchInput?.focus();
        }
      );
    }
  };
  updateSearchKey = (searchText) => {
    this.setState({ searchText: searchText });
  };
  doSearch = (searchText) => {
    const { onSubmit } = this.props;
    if (searchText.trim() === "") {
      return;
    }
    if (this.state.fineSearch !== "") {
      this.fireCustomerCapsuleSearch();
      return;
    }
    onSubmit(searchText);
  };
  doPowerSearch = (searchText) => {
    this.setState({ searchText }, () => {
      this.doSearch(searchText);
    });
  };
  updateInputValue = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  };
  fireAccountNameSearch = (e) => {
    let searchId = e;
    if (searchId) {
      this.props.setActiveFilterAndValue(this.state.searchType, searchId);
      this.setState({ customerID: searchId });
      setTimeout(() => {
        this.props.onCustomerSubmit(searchId);
        const { onSubmit } = this.props;
        this.state.searchText && onSubmit(this.state.searchText);
      }, 1000);
    }
  };
  ClearSearch = () => {
    this.setState({
      searchText: "",
    });
  };
  ClearAdvSearch = () => {
    this.setState(
      {
        fineSearch: "",
        accountNameValue: "",
      },
      () => this.props.resetRefinedSearch()
    );
  };
  ClearAdvnSearch = () => {
    this.setState(
      {
        accountNameValue: "",
        fineSearch: "",
      }
      //() => this.props.resetRefinedSearch()
    );
  };
  handleIframeLoad = () => {
    this.setState({
      iframeLoaded: true,
    });
  };

  handleShowBooks = (book) => {
    let bookType = "";
    let bookDownloadURL = "";

    switch (book) {
    case "channelpartnerbook":
            bookType = CPURL
            bookDownloadURL = ChannelPartnerBook
    break;
    }
    this.setState({ showBooksModal: true, bookType: bookType, bookDownloadURL: bookDownloadURL });
  }

  showFeedback = () => {
    this.props.showFeedback()
  }

  toggleDocDepoFun = () => {
    this.props.toggleDocDepoFun()
  }

  showHelpVideos = () => {
    this.setState({ showVideo: !this.state.showVideo })
  }

  carouselPositionChanged = (coords) => {
    this.setState({ carouselPosition: coords })
  }

  updateImageMapperLoaded = () => {
    // this.setState({ imageMapperLoaded: true })
    this.props.setImageMapperLoaded(true)
  }
  handleTextClick = (url) => {
    window.open(url, '_blank');
  }

  render() {
    const size = 15;
    const searchHistory = slice(
      filter(
        uniqBy(this.state.searchHistory, "data"),
        (item) => item.data !== "*:*"
      ),
      0,
      size
    );



    let settings = {
      dots: true,
      // fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      waitForAnimate: false,
      autoplay: true,
      arrows: false,
    };

    const isChannelPartner = localStorage.getItem("isChannelPartner")
    return (
      <>
        <section id="content">
          <div className="cp-content-container">
            {
              this.props.imageMapperLoaded ? (
                
                null
              ) : (
                <div className="text-center">
                  <img className="loading-img" src={logo} alt="loading"></img>
                </div>
              )

            }

            {this.context.currentHomeImageMapper && React.cloneElement(this.context.currentHomeImageMapper, {
              showFeedback: this.showFeedback,
              showDocDepo: this.toggleDocDepoFun,
              showHelpVideos: this.showHelpVideos,
              handleShowBooks: this.handleShowBooks,
              carouselPositionChanged: this.carouselPositionChanged,
              updateImageMapperLoaded: this.updateImageMapperLoaded,
              imageMapperLoaded:this.props.imageMapperLoaded
            })}
            {/* <HomePageImageMapper
              showFeedback={this.showFeedback}
              showDocDepo={this.toggleDocDepoFun}
              showHelpVideos={this.showHelpVideos}
              handleShowBooks={this.handleShowBooks}
              carouselPositionChanged={this.carouselPositionChanged}
              updateImageMapperLoaded={this.updateImageMapperLoaded}
            /> */}
            {this.props.imageMapperLoaded ?
            <div className={isChannelPartner === "1" ? "channel-partner-home-search" : "internal-user-home-search"} id="SearchAutoSuggestionsPrimary">
              <div className="col-12 mx-auto" id="searchpanel">
                <div className="container">
                  <div className={"row height d-flex justify-content-center align-items-center " + (isChannelPartner === "1" ? "" : "homesearch-autosuggest")}>
                    <div className="col-md-8">
                      <div
                        className="search mt-3 channel-partner-home-search_autosuggest"
                      >
                        <DocumentAutoSuggestNew
                          onSearchValueChange={this.onFineSearchChange}
                          fireSearch={this.fireSearch}
                          value={this.state.textSearch}
                        />
                        {this.state.searchText !== "" && (
                          <>

                            <span
                              className="clearsearch sright channel-partner-home-search_clearsearch"
                              title="Clear"
                              onClick={this.ClearSearch}
                              translate="no"
                            >
                              X
                            </span>
                          </>
                        )}

                        {this.state.refineSearch && (
                          <div id="refinesearch" className="pt-2">
                            <div id="SearchOppIDPrimary">
                              {this.state.searchType &&
                                this.state.searchType === "Account Name" ? (
                                <div>
                                  <AccountNameAutoSuggest
                                    fireSearch={this.fireAccountNameSearch}
                                    value={this.state.accountNameValue}
                                    onSearchValueChange={
                                      this.updateAccountNameSearchKey
                                    }
                                    setActiveFilterAndValue={
                                      this.props.setActiveFilterAndValue
                                    }
                                    searchType={this.state.searchType}
                                  />
                                  {this.state.accountNameValue != "" && (
                                    <span
                                      className="clearsearch sright channel-partner-home-search_advclearsearch"
                                      title="Clear"
                                      onClick={this.ClearAdvnSearch}
                                      translate="no"
                                     
                                    >
                                      X
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <input
                                    onChange={this.onFineSearchChange}
                                    value={this.state.fineSearch}
                                    className="form-control form-control-sm search-boxes channel-partner-home-search_box"
                                    type="text"
                                    placeholder={
                                      "Sales Territory ID / Project ID / PSA Project ID / Opportunity ID / Account Name / SAID"
                                    }
                                    onKeyUp={this.fireSearch}
                                    ref={(elem) =>
                                      (this.fineSearchInput = elem)
                                    }
                                    autoFocus
                                  />
                                  {this.state.fineSearch != "" ? (
                                    <span
                                      className="clearsearch sright"
                                      title="Clear"
                                      onClick={this.ClearAdvSearch}
                                      translate="no"
                                    >
                                      X
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                              {/* <label>{this.state.searchType}</label>  */}
                            </div>
                            <div className="col-3" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-1 channel-partner-search_box" ><i className="fas fa-repeat fa-lg channel-partner-search_icon"  onClick={(e) => this.setState({ textSearch: '' })}
        title="Click to clear the search"></i> </div>
                  </div>
                </div>
              </div>
            </div> : null
            }
            
          </div>
          {/* <img src={BGI} alt="BGI" className="page_background_image" /> */}

          
        </section>
        {
          this.state.showVideo && (
            <KMNuggets
              showVideoPopup={this.state.showVideo}
              hideVideoPopup={this.showHelpVideos}
            />
          )
          // <Modal
          //   show={this.state.showVideo}
          //   onHide={() => this.setState({ showVideo: false })}
          //   centered
          // >
          //   <Modal.Header closeButton>
          //     <Modal.Title>Help Videos</Modal.Title>
          //   </Modal.Header>
          //   <Modal.Body>
          //     <KMNuggets />
          //   </Modal.Body>
          // </Modal>
        }

        {this.state.showBooksModal && (
          <Modal
            show={this.state.showBooksModal}
            onHide={() => this.setState({ showBooksModal: false })}
            centered
            dialogClassName="modal-dialog modal-lg full-data-modal homepage-books-modal"
            animation={false}
          >
            <Modal.Body className="channel-partner-search_modalbody">
              {this.state.iframeLoaded && (
                <div className="homepage-book-modal_close channel-partner-search_modalclose">
                <CloseButton
                  className="channel-partner-search_modalcloseicon"
                  onClick={() =>
                    this.setState({
                      showBooksModal: false,
                      bookType: "",
                      iframeLoaded: false,
                    })
                  }
                />
              </div>)}
              {this.state.iframeLoaded ?
                (<div
                  className="homepage-book-modal_close channel-partner-search_modalclose"
                >
                  <a href={this.state.bookDownloadURL} download>
                    <i
                      className="fa fa-download float-end channel-partner-search_modalcloseicon"
                      aria-hidden="true"
                    />
                  </a>
                </div>) : (<div className="text-center">
                  <img className="loading-img" src={logo} alt="loading"></img>
                </div>)}
              <iframe
                src={this.state.bookType}
                className={this.state.iframeLoaded ? "channel-partner-search_iframe d-block" : "this.state.iframeLoaded d-none"}
               
                onLoad={this.handleIframeLoad}
                loading="auto"
              ></iframe>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}
export default SearchForm;
