import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Accordion } from "react-bootstrap";
import ChatBotAccordion from "./ChatBotAccordion";
import ReactPlayer from "react-player";
import { Modal } from "react-bootstrap";

export default function ChatBotCarousel(props) {
  const data = props.data.other_responses;
  const [showCarousel, setShowCarousel] = useState(true);
  const [videoPlayer, setVideoPlayer] = useState(false);
  const [videoTitle, setVideoTitle] = useState([]);
  const [videoUrl, setVideoURL] = useState([]);
  const handleDocPreview = (docUrl) => {
    if (docUrl.indexOf("8082") > 0 || docUrl.indexOf("hpedelta") > 0 ) props.handlePreview(docUrl);
    else window.open(docUrl, "_blank");
    // console.log("docccc", docUrl);
  };
  const handleCheckURL = (docUrl, docTitle) => {
    if (docUrl.includes(".mp4")) {
      setVideoPlayer(true);
      setVideoURL(docUrl);
      setVideoTitle(docTitle);
    } else {
      handleDocPreview(docUrl);
    }
  };
  const handleClose = () => setVideoPlayer(false);

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    height: 30,
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
  };

  const indicatorStyles = {
    background: "#fff",
    cursor: "pointer",
    height: "8px",
    width: "8px",
    margin: "0 2px",
    borderRadius: "50%",
    display: "inline-block",
    transition: "background-color 0.6s ease",
  };

  const scrollClick = () => {
    setTimeout(() => {
      document
        .getElementById(props.data.chat_id)
        .scrollIntoView({ block: "end" });
    }, 500);
  };

  const handleChatBotTitleName = (title) => {
    if(title.length > 65) {
      return title.substring(0, 65) + '...'
    }
    return title
  }

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     document.getElementById(props.data.chat_id).scrollIntoView({behavior:"smooth"});
  //   },500)
  // },[props.data.chat_id])
  return (
    <div className="App">
      <Accordion key={props.data.chat_id} onClick={scrollClick}>
        <ChatBotAccordion
          eventKey={`${props.data.chat_id}`}
          message={props.data.message}
        ></ChatBotAccordion>
        <Accordion.Collapse
          eventKey={`${props.data.chat_id}`}
          className="channel-partner-chatbot-accordion"
        >
          <div className="channel-partner-chatbot-accordion_div1">
            <div
              class="chatbot-carousel channel-partner-chatbot-accordion_div2"
              id={`${props.data.chat_id}`}
            >
              <Carousel
                className="channel-partner-chatbot-carousel"
                showStatus={false}
                infiniteLoop={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <div
                      onClick={onClickHandler}
                      className="channel-partner-chatbot-prev"
                    >
                      {" "}
                      {`❮`}{" "}
                    </div>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <div
                      onClick={onClickHandler}
                      className="channel-partner-chatbot-right"
                    >
                      {" "}
                      {`❯`}{" "}
                    </div>
                  )
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  if (isSelected) {
                    return (
                      <li
                        className="channel-partner-chatbot-indicator channel-partner-chatbot-indicator_selected"
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                      />
                    );
                  }
                  return (
                    <li
                      className="channel-partner-chatbot-indicator channel-partner-chatbot-indicator_unselected"
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    />
                  );
                }}
              >
                {data.map((item, index) => (
                  <div
                    key={"slide" + index}
                    className="channel-partner-chatbot-carousel"
                    
                  >
                    <div
                      class="chatbot-carousel-title channel-partner-chatbot-carousel_title"
                     
                      title={item.title}
                    >
                      {handleChatBotTitleName(item.title)}
                    </div>
                    <div
                      class="chatbot-carousel-url channel-partner-chatbot-carousel_url"
                      onClick={() => handleCheckURL(item.url, item.title)}
                    >
                      {item.url}
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
      <Modal
        show={videoPlayer}
        onHide={handleClose}
        dialogClassName="modal-90w"
        centered
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>{videoTitle}</Modal.Title>
          <button
            type="button"
            translate="no"
            onClick={() => handleClose()}
            class="btn-close"
            data-dismiss="modal"
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url={videoUrl}
            controls={true}
            width="1100"
            height="360"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
