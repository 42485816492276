import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap";
import DocumentsAutoSuggest from "./DocumentAutoSuggest";
import URLConfig from "../URLConfig";
import TrackingService from "../TrackingService";
import DeltaHomeContext from "../Context/DeltaHomeContext";
import DocumentAutoSuggestNew from "./DocumentAutoSuggestNew";

class SearchFormHeader extends React.Component {
  static contextType = DeltaHomeContext
  constructor(props) {
    super(props);
  }
  state = {
    searchText: "",
    customerID: "",
    showRefineSearch: false,
    fineSearch: "",
    searchType: "",
    accountNameValue: "",
    showRefineToggleButton: false,
    showwordcloud: false,
    advancedSearch: false,
    zIndex: 9
  };
  handleReset = () => {
    if (this.props.onReset) {
      console.log("Inside handle Reset");
      this.props.onReset();  
    } 
  };

  toggleRefineSearch = () => {
    this.setState((prevState) => ({
      showRefineSearch: !prevState.showRefineSearch,
    }));
  };
  setActiveFilter = (filter) => {
    this.setState({ searchType: filter });
  };
  onFineSearchChange = (e) => {
    let fineSearch = e.target.value;
    this.setState({ fineSearch, customerID: fineSearch });
    this.identifySearchType(fineSearch);
  };
  isNumber = (str) => {
    var reg = new RegExp("^[0-9]+$");
    return reg.test(str);
  };
  identifySearchType = (fineSearch) => {
    var hasNumber = /\d/;
    if (fineSearch.length > 2) {
      if (this.isNumber(fineSearch) && fineSearch.length < 10) {
        this.setState({ searchType: "ST ID" });
      } else if (fineSearch.toUpperCase().indexOf("PR-") === 0) {
        this.setState({ searchType: "PR ID" });
      } else if (
        (fineSearch.toUpperCase().indexOf("OPP") === 0 ||
          fineSearch.toUpperCase().indexOf("OPE") === 0) && fineSearch.length < 16
      ) {
        this.setState({ searchType: "OPP ID" });
      } else if (
        URLConfig.get_countryCode().indexOf(
          fineSearch.substring(0, 2).toUpperCase()
        ) !== -1 &&
        fineSearch.indexOf("-") === 3 &&
        fineSearch.length == 9
      ) {
        this.setState({ searchType: "WBS ID" });
      } else if (
        (fineSearch.length === 8 || fineSearch.length === 10 || fineSearch.length === 12) &&
        fineSearch.includes("-") === false &&
        !this.isNumber(fineSearch) &&
        hasNumber.test(fineSearch)
      ) {
        this.setState({ searchType: "Serial ID" });
      } else if (
        fineSearch.length === 12 &&
        fineSearch.includes("-") === false &&
        this.isNumber(fineSearch)
      ) {
        this.setState({ searchType: "Service Agreement Id" });
      } else {
        this.setState({
          searchType: "Account Name",
          accountNameValue: fineSearch,
        });
      }
    }
    if (fineSearch.length === 0) {
      this.setState({ searchType: "", accountNameValue: "" });
    }
  };
  fireCustomerCapsuleSearch = () => {
    const { onCustomerSubmit } = this.props;
    const { onSubmit } = this.props;
    this.props.setActiveFilterAndValue(
      this.state.searchType,
      this.state.fineSearch
    );
    const CustID = this.state.customerID
      .trim()
      .replace(/[#?&@]/g, " ")
      .toUpperCase()
      .split(".")[0];

    //Validations
    if (this.state.searchType && this.state.searchType === "OPP ID") {
      if (this.state.fineSearch.trim().length !== 14) {
        toast.error("Please enter valid Opportunity ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "WBS ID") {
      if (this.state.fineSearch.trim().length !== 9) {
        toast.error("Please enter valid WBS ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "ST ID") {
      let re = new RegExp("^[0-9]*$");
      if (!this.state.fineSearch.match(re)) {
        toast.error("Please enter valid ST ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "PR ID") {
      if (!this.state.fineSearch.trim().startsWith("PR-")) {
        toast.error("Please enter valid PR ID", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (
      this.state.searchType &&
      this.state.searchType === "Account Name"
    ) {
      let re = new RegExp("/^([0-9]|[a-z])+([0-9a-z]+)$/i");
      if (
        !this.state.accountNameValue.match(re) &&
        this.state.accountNameValue.trim().length < 5    // change the length from 10 to 5 because some of the file names are less than 10 digit
      ) {
        toast.error("Please enter valid Account Name", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    } else if (this.state.searchType && this.state.searchType === "Serial ID") {
      if (this.state.fineSearch.trim().length < 12) {
        if (this.state.fineSearch.trim().length < 10) {
          if (this.state.fineSearch.trim().length < 8) {
            toast.error("Please enter valid Serial Number ID ", {
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            return;
          }
        }
      }
    }

    if (
      this.state.searchType &&
      (this.state.searchType === "OPP ID" || this.state.searchType === "WBS ID")
    ) {
      const Config = URLConfig.GetSearchKeyByOppID(
        this.state.fineSearch.trim()
      );

      axios(Config).then((res) => {
        if (res?.data && res?.data?.response) {
          if (this.state.searchText === "")
            this.setState({ searchText: res.data.response });
          //this.getCustDetails();
          onCustomerSubmit(
            this.state.fineSearch
              .trim()
              .replace(/[#?&@]/g, " ")
              .toUpperCase()
              .split(".")[0]
          );
          onSubmit(this.state.searchText.replace(/[#?&@]/g, " "));
        } else if (res?.data && res?.data?.error) {
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
      });
    } else if (this.state.searchType === "Serial ID") {
      onCustomerSubmit(this.state.fineSearch);
    } else {
      const CustID =
        this.state.searchType === "Account Name"
          ? this.state.accountNameValue
          : this.state.fineSearch
            .trim()
            .replace(/[#?&@]/g, " ")
            .toUpperCase()
            .split(".")[0];

      setTimeout(() => {
        onCustomerSubmit(CustID);
        if (this.state.searchText.trim() === "") {
          const { ResetKnowledgeAndRefCapsule } = this.props;
          ResetKnowledgeAndRefCapsule();
        } else {
          onSubmit(this.state.searchText.replace(/[#?&@]/g, " "));
        }
      }, 1000);
      //this.state.searchText&&this.doSearch(this.state.searchText.replace(/[#?&@]/g, " "));
    }
  };

  fireSearch = (e) => {
    this.props.triggeredRefineSearch()
    this.props.setActiveFilterAndValue(
      this.state.searchType,
      this.state.fineSearch
    );
    console.log(" fireSearch keyCode ", this.state.searchType,
      this.state.fineSearch)
    if (e.keyCode === 13) {
      // Enter key
      console.log(" fireSearch keyCode ", this.state.searchType,
        this.state.fineSearch)
      this.fireCustomerCapsuleSearch();
    }
  };

  ClearSearch = () => {
    this.setState({
      searchText: "",
      customerID: "",
      fineSearch: "",
      searchType: "",
      accountNameValue: "",
    });
  };

  ClearFreeSearch = () => {
    this.setState({
      searchText: "",
    });
  };



  componentDidMount() {
    const accountNameValue =
      this.props.activeFilter === "Account Name"
        ? this.props.CustomerIDValue
        : "";
    const searchType = this.props.activeFilter;
    const fineSearch =
      this.props.activeFilter != "Account Name"
        ? this.props.CustomerIDValue
        : "";
    const showRefineToggleButton = this.props.CustomerIDValue.trim() === "";
    this.setState({
      searchText: this.props.searchText,
      accountNameValue,
      searchType,
      fineSearch,
      showRefineToggleButton,
      showRefineSearch: !showRefineToggleButton,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchText !== prevProps.searchText) {
      this.setState({ searchText: this.props.searchText });
    }
    
    if (
      this.props.activeFilter === "Account Name" &&
      prevProps.CustomerIDValue !== this.props.CustomerIDValue
    ) {
      const showRefineToggleButton = this.props.CustomerIDValue.trim() === "";
      this.setState(
        {
          accountNameValue: this.props.CustomerIDValue,
          fineSearch: this.props.CustomerIDValue,
          searchType: this.props.activeFilter,
          showRefineToggleButton,
          showRefineSearch: true,
        },
        () => this.fireAccountNameSearch(this.props.CustomerIDValue)
      );
    }
  }

  updateAccountNameSearchKey = (accountNameValue) => {
    if (accountNameValue.length != 0) {
      this.setState({ accountNameValue, fineSearch: accountNameValue });
      this.identifySearchType(accountNameValue);
    } else {
      this.setState(
        {
          accountNameValue: "",
          fineSearch: "",
          searchType: "",
          customerID: "",
        },
        () => {
          this.fineSearchInput.focus();
        }
      );
    }
    this.props.isClose(false);
  };

  updateSearchKey = (searchText) => {
    var refineSearch = this.state.accountNameValue
      ? this.state.accountNameValue
      : this.state.fineSearch;
    this.setState({ searchText: searchText });
    this.props.isClose(false);
    this.setState({ fineSearch: refineSearch });
  };

  doSearch = (searchText) => {
    this.setState({ advancedSearch: false });
    const { onSubmit } = this.props;
    if (searchText.trim() === "") {
      return;
    }
    if (this.state.searchType !== "") {
      this.fireCustomerCapsuleSearch();
      return;
    }
    if (this.state.searchType === "") {
      const { ResetCustomerCapsule } = this.props;
      ResetCustomerCapsule();
    }
    onSubmit(searchText);
  };


  fireAccountNameSearch = (searchId) => {
    if (searchId) {
      this.props.setActiveFilterAndValue(this.state.searchType, searchId);
      this.setState({ customerID: searchId });
      setTimeout(() => {
        this.props.onCustomerSubmit(searchId);
        if (this.state.searchText.trim() === "") {
          const { ResetKnowledgeAndRefCapsule } = this.props;
          ResetKnowledgeAndRefCapsule();
        } else {
          const { onSubmit } = this.props;
          onSubmit(this.state.searchText.replace(/[#?&@]/g, " "));
        }
      }, 1000);
    }
  };


  onFocusSearch = () => {
    this.setState({ zIndex: 999 })
  }

  onFocusOutSearch = () => {
    this.setState({ zIndex: 9 })
  }

  render() {
    console.log("this.props.searchText", this.props.searchText, this.state.searchText)
    const searchType = this.props.activeFilter;
    const isChannelPartner = localStorage.getItem("isChannelPartner")
    return (
      <>
        {isChannelPartner === "1" && window.location.pathname === "/search1" ? (
          <>
            <div
              className={"col-4 pe-1 " + (isChannelPartner === "1" ? "channel-partner-search channel-partner-search_header" : "internal-user-search channel-partner-search_header")}
              id="SearchAutoSuggestions"
              onClick={() => console.log("CLICKED")}
              
            >
              <DocumentsAutoSuggest
                fireSearch={this.doSearch}
                value={this.state.searchText}
                onSearchValueChange={this.updateSearchKey}
              //isClose = {this.SurveyClose}
              //() => this.SurveyClose
              />
              {this.state.searchText !== "" ? (
                <span
                  className="clearsearch channel-partner_clearsearch"
                  title="Clear"
                  onClick={this.ClearFreeSearch}
                  translate="no"

                >
                  X
                </span>
              ) : (
                ""
              )}
            </div>

          </>

        ) : (
          <>
            {isChannelPartner === "1" && window.location.pathname === "/search" ?
              (<>
                <div className="row w-75 pe-0 row height d-flex justify-content-center align-items-center channel-partner-head-search">
                  <div className="col-md-8">
                    <div className="CP-head-search">
                      <div className="searchbox_adj" id="">
                        <DocumentAutoSuggestNew
                          onSearchValueChange={this.updateSearchKey}
                          fireSearch={this.doSearch}
                          value={this.state.searchText}
                          onFocusSearch={this.onFocusSearch}
                          onFocusOutSearch={this.onFocusOutSearch}
                        />
                        {/* {this.state.searchText !== "" && (
                    <>
                  
                  <span
                    className="clearsearch sright"
                    title="Clear"
                    onClick={this.ClearSearch}
                    translate="no"
                   
                  >
                    X
                  </span>
                  </>
                )} */}
                      </div>
                    </div>
                  </div>
                  <div className="col-1 channel-partner_resetsearch"><i className="fas fa-repeat fa-lg" onClick={this.handleReset}
                    title="Click to clear the search"></i></div>
                  
                  
                </div>
              </>)

              : (
                <></>
              )}
          </>

        )}
      </>
    );
  }
}
export default SearchFormHeader;
