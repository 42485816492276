import axios from "axios";
// import { unescape } from "lodash";
// import { unescape } from "lodash";

const URLConfig = (() => {
    const ENV = process.env.REACT_APP_ENV;
    const enableOAuthLogin = ENV === "PROD" || ENV === "UAT" ? true : false;

    const ANPS_API = process.env.REACT_APP_API;
    // const ANPS_API = "https://localhost:44356/";

    const DataAPIURL = "https://hpedelta.com:8983/";
    const CustomerCapsuleAPI = "https://hpedelta.com:5003/";
    const CP_NOTIFICATION_API = process.env.REACT_APP_CP_NOTIFICATION_API

    const get_Env = () => ENV;
    const isChannelPartner = localStorage.getItem("isChannelPartner")

    const isOAuthLoginEnabled = () => {
        return enableOAuthLogin;
    };
    const CountryCodes = [
        "CA",
        "AS",
        "MP",
        "GU",
        "UM",
        "US",
        "AR",
        "UY",
        "PY",
        "BR",
        "MX",
        "CO",
        "CL",
        "AQ",
        "GS",
        "FK",
        "VE",
        "PR",
        "BO",
        "EC",
        "PE",
        "BS",
        "BQ",
        "BM",
        "BL",
        "BB",
        "AW",
        "AI",
        "AG",
        "MS",
        "MF",
        "LC",
        "KY",
        "KN",
        "JM",
        "HT",
        "GY",
        "GF",
        "GD",
        "DO",
        "DM",
        "CW",
        "CU",
        "VI",
        "VG",
        "VC",
        "TT",
        "TC",
        "SX",
        "SR",
        "AN",
        "CR",
        "BZ",
        "NI",
        "HN",
        "GT",
        "SV",
        "PA",
        "CK",
        "CC",
        "NF",
        "MH",
        "KI",
        "IO",
        "HM",
        "FM",
        "FJ",
        "CX",
        "WS",
        "VU",
        "TV",
        "TO",
        "TK",
        "SB",
        "PW",
        "PN",
        "PG",
        "NU",
        "NR",
        "AU",
        "NZ",
        "KR",
        "KP",
        "BT",
        "BN",
        "BD",
        "AF",
        "MV",
        "MN",
        "MM",
        "LK",
        "LA",
        "KH",
        "TL",
        "NP",
        "CN",
        "JP",
        "IN",
        "MO",
        "HK",
        "MY",
        "ID",
        "VN",
        "TH",
        "PK",
        "PH",
        "TW",
        "SG",
        "AZ",
        "AM",
        "GE",
        "UZ",
        "TM",
        "TJ",
        "AD",
        "ES",
        "CH",
        "LI",
        "IE",
        "BW",
        "AO",
        "NA",
        "MZ",
        "GH",
        "ZW",
        "UG",
        "TZ",
        "RW",
        "CM",
        "CI",
        "CG",
        "CF",
        "CD",
        "BJ",
        "BI",
        "BF",
        "NE",
        "MW",
        "MR",
        "MQ",
        "ML",
        "LS",
        "LR",
        "KM",
        "GW",
        "GQ",
        "GN",
        "GM",
        "GA",
        "ET",
        "ER",
        "EH",
        "DJ",
        "CV",
        "ZM",
        "TG",
        "TF",
        "TD",
        "SZ",
        "ST",
        "SS",
        "SO",
        "SN",
        "SL",
        "SH",
        "SD",
        "SC",
        "PM",
        "IS",
        "GL",
        "FO",
        "DK",
        "AX",
        "LV",
        "LT",
        "FI",
        "EE",
        "GR",
        "CY",
        "BH",
        "LY",
        "LB",
        "KW",
        "JO",
        "IR",
        "IQ",
        "YE",
        "SY",
        "OM",
        "AE",
        "EG",
        "ZA",
        "SA",
        "QA",
        "BE",
        "LU",
        "XK",
        "BA",
        "AL",
        "MT",
        "MK",
        "ME",
        "MD",
        "XK",
        "NG",
        "MA",
        "KE",
        "HU",
        "CZ",
        "SK",
        "RO",
        "PL",
        "BY",
        "UA",
        "BG",
        "HR",
        "RS",
        "SI",
        "NC",
        "MU",
        "MG",
        "GP",
        "DZ",
        "YT",
        "WF",
        "TN",
        "RE",
        "PF",
        "BV",
        "NO",
        "SJ",
        "IT",
        "VA",
        "SM",
        "MC",
        "FR",
        "JE",
        "IM",
        "GI",
        "GG",
        "GB",
        "IL",
        "PS",
        "KZ",
        "KG",
        "NL",
        "SE",
        "AT",
        "DE",
        "PT",
        "TR",
        "RU",
      ];
      const apiKey = "QnQCn0hujNxHig+noHP4jg==";
      const base64Key = "_gqn6ugFBnIHryL9IBj3wlLZoVQJDFJLqXrQZzKcBOU=";


      const encryptMessage = (url) => {
        const combinedMessage = url + base64Key;
        // const encryptedUrl = btoa(combinedMessage); // Base64 encoding
        const encryptedUrl = btoa(unescape(encodeURIComponent(combinedMessage)));
        return encryptedUrl;
      }; 

      const headers = {
        
        "Content-Type": "application/json",
        "SOLR-API-KEY": apiKey, // Define your apiKey outside or pass it as a parameter if needed
        
      };


      const getnewsolrBaseURL = (URL,customHeaders={})=>{
        const encryptedURL = encryptMessage(URL)
        const payload = {
          solr_url_encrypted:encryptedURL
        }
        return axios
        .post("https://hpedelta.com:8984/search_kb", payload, {headers:{...headers,...customHeaders}})
      }
      
    const getSolrBaseURL =()=>{
        if(isChannelPartner === "1"){
          return "solr/sharepoint_index_cp"
          // return "solr/sharepoint_index"
        }
        else{
          return "solr/sharepoint_index"
        }
    }

    const isNDA =(withHyphen)=>{
        if(isChannelPartner === "1"){
          return ""
        }
        else{
          if(withHyphen){
            return "&fq=-nda:%22True%22"
    
          }
          else{
            return "&fq=nda:%22True%22"
    
          }  
        }
    }

    const isDisclosureLevel =(withEncoded =false)=>{
        if(isChannelPartner === "1"){
          return ""
        }
        else{
          if(withEncoded){
            return "disclosure_level%2c"
          }
          else{
            return "disclosure_level,"  
          }
         
         
        }
    }
    const fields =
    "id,title,isgoldcollateral,file,url,"+isDisclosureLevel()+"rating,file_type,doc_source,language_s,competitors_covered,research_vendors,industry_segment,industry_vertical,system_integrators,technology_partners,product_line,business_segment,region,country,creation_date,document_type,sysmodtimestamp,modified_date,publish_date,asset_creator,practice,recommended_by,alligned_initiative,service_type,nda,ndamails_raw,score,isarchived";

    const URL_KnowledgeCapsule =
    DataAPIURL +
    getSolrBaseURL()+"/select?defType=edismax&fl=" +
    // "solr/sharepoint_index/select?defType=edismax&fl=" +
    fields +
    '&group.field=document_type&group=true&indent=on&q={searchterm}&rows=500&wt=json&group.limit=150&fq=scope:"Service Capsule" AND -document_type:"Services" AND -document_type:"Guides" AND -document_type:"Presentations" AND -document_type:"POC" AND -document_type:"Multimedia" AND -document_type:"Internal Trainings"&fq=-file_type:"msg"';

    const URL_SKU =
    DataAPIURL +
    "solr/product_hierarchy/select?fl=sku,sku_description,End_of_Support_Date,document_type,url,service_type,document,sdg_url,practice_owner,template_code,product&indent=on&q={searchterm}&rows=10000&sort=practice_owner%20desc&wt=json";

    const URL_ReUseInsight =
    DataAPIURL +
    `solr/insights/select?indent=on&q="{searchterm}"&rows=1000&wt=json&group=true&group.field=Opportunity_Sales_Stage&group.sort=creation_date%20desc&group.limit=500&sort=Opportunity_Sales_Stage%20asc&fq=Account_ST_ID:[%27%27%20TO%20*]`;

    const URL_ActIDByOPP =
    CustomerCapsuleAPI +
    "services/data/v38.0/sobjects/query2?q=Select%20Country__c,%20Account_Shipping_Country__c,%20Multi_Country_Type__c,%20Account.Name,%20IsPrivate,%20Account.Focus_Account__c,%20Account.Account_ST_ID__c,%20Account.Account_ST_Name__c,%20Account.Top_Parent_ST_ID__c,%20Account.Top_Parent_ST_Name__c,%20Id,%20Opportunity_ID__c,%20convertCurrency(Amount),%20IsWon,%20IsClosed,%20CreatedDate,%20Fiscal,%20FiscalYear,%20FiscalQuarter,%20Win_Loss_Reason__c,%20Opportunity_Sales_Stage__c,%20Status__c,%20CloseDate%20from%20Opportunity%20WHERE%20Opportunity_ID__c%20=%20%27{oppid}%27%20";

    const URL_OppIDByWbsID =
    DataAPIURL +
    'solr/ppmc/select?fl=wbs_id,opportunity_id,account_name&fq=wbs_id:"{wbsid}"&indent=on&q=*:*&rows=1&wt=json';

    const URL_OppDataByActID =
    CustomerCapsuleAPI +
    "services/data/v38.0/sobjects/query2?q=Select%20Account.Account_ST_Name__c,%20Opportunity_ID__c,%20convertCurrency(Amount),%20IsWon,%20IsClosed,%20Opportunity_Sales_Stage__c%20,%20Status__c%20,IsPrivate%20from%20Opportunity%20WHERE%20Opportunity_Sales_Stage__c !='HPE Not Pursued' AND%20{actid}%27%20Order%20By%20SystemModstamp%20DESC";

    const URL_OppDataByPRID =
    CustomerCapsuleAPI +
    "services/data/v38.0/sobjects/query2?q=SELECT%20PSA_Location_Country__c,%20pse__Account__r.Name,%20pse__Account__r.Focus_Account__c,%20pse__Account__r.Account_ST_ID__c,%20pse__Account__r.Account_ST_Name__c,%20pse__Account__r.Top_Parent_ST_ID__c,%20pse__Account__r.Top_Parent_ST_Name__c,%20Id,%20pse__Project_ID__c,%20PSA_Overall_Status__c,%20PSA_Financial_Status__c,%20pse__Project_Status__c,%20PSA_Project_Friendly_Name__c%20FROM%20pse__Proj__c%20WHERE%20pse__Project_ID__c%20=%20%27{oppid}%27";

    const URL_AccountIdByAccountName =
    DataAPIURL +
      "solr/account/select?fq=Account_ST_Name:%22{accountname}%22&indent=on&q=*:*&rows=2&wt=json";
    
    const URL_ReUseCapsule =
    DataAPIURL +
        getSolrBaseURL()+"/select?defType=edismax&fl=" +
        // "solr/sharepoint_index/select?defType=edismax&fl=" +
        fields +
        '&group.field=document_type&group=true&indent=on&q={searchterm}&rows=500&wt=json&group.limit=15&fq=scope:"Reuse"&fq=-file_type:"msg"';

    const URL_FooterLinks =
    ANPS_API + "api/anpslinks/GetAnpsLinks/ANPSFoorterLink";
    
    const URL_Suggestions = DataAPIURL + "solr/"+(isChannelPartner==="1"?"auto_suggest_cp":"auto_suggest")+"/suggest?wt=json&q=";
    const URL_Suggestions_all =
    DataAPIURL + "solr/auto_suggest_all/suggest?wt=json&q=";
    const getURL_GetUserLoginTime = () => {
        return `${ANPS_API}api/User/GetUserLoginTime`;
    }

    const getURL_EmployeeAPI = function (AccountID) {
        return `${ANPS_API}api/DeltaUser/DeltaUser/`;
    };

    const getURL_GetChannelPartnerUser = function () {
        return `${ANPS_API}api/DeltaUser/ChannelPartnerUserDetails/`;

    }
    const getURLDeltaAPI = () => ANPS_API;

    const ApplyAuth = function (URL) {
        return {
          method: "get",
          url: URL,
          headers: {
            Authorization: "Basic U2ZkY1VzZXI6VTJaa1kzSmxZV1JBTVRJeU1BPT0=",
          },
        };
    };

    const GetUserRoles = () => {
        const roles = localStorage.getItem("roles")?.split(",");
        let Roles = "&fq=";
        roles?.forEach((list, index) => {
          if (index === 0) Roles += "persona_role:*" + list + "*";
          else Roles += " OR persona_role:*" + list + "*";
        });
        return Roles;
      };
    const getURLKnowledgeCapsule = function (searchterm) {
        const roles = GetUserRoles();
        return (
          URL_KnowledgeCapsule.toString().replace("{searchterm}", searchterm) +
          roles
        );
    };

    const getURLSKU = function (searchterm) {
        return (
          URL_SKU.toString().replace("{searchterm}", searchterm) + GetUserRoles()
        );
    };

    const get_APIHost = () => {
        return DataAPIURL;
    };

    const getURLReUseInsight = (searchterm) => {
        const roles = GetUserRoles();
        return (
          URL_ReUseInsight.toString().replace("{searchterm}", searchterm) + roles
        );
    };

    const getURLReUseCapsule = function (searchterm) {
        const roles = GetUserRoles();
        return (
          URL_ReUseCapsule.toString().replace("{searchterm}", searchterm) + roles
        );
    };

    const getURLActIDOPP = function (opportunity_id) {
        return ApplyAuth(
          URL_ActIDByOPP.toString().replace("{oppid}", opportunity_id)
        );
    };

    const getURLOpportunityIDByWbsID = function (wbsID) {
        return ApplyAuth(URL_OppIDByWbsID.toString().replace("{wbsid}", wbsID));
    };

    const getUrlOppDataByActID = (AccountID) => {
        return ApplyAuth(
          URL_OppDataByActID.toString().replace("{actid}", AccountID)
        );
    };

    const getUrlOppDataByPRID = (AccountID) => {
        return URL_OppDataByPRID.toString().replace("{oppid}", AccountID);
    };

    const getUrlAccountIdByAccountName = (AccountName) => {
        return URL_AccountIdByAccountName.toString().replace(
            "{accountname}",
            AccountName
        );
    };

    const getURL_FooterLinks = () => {
        return ApplyAuth(URL_FooterLinks);
    };

    const getNugFlixCategorisedVideoData = () => {
        const url =
          "https://hpedelta.com:8983/solr/nugflix/select?fq=unique_file:%22Home%20Page%20Overview%22&indent=on&q=*:*&rows=25&wt=json"
        return url;
    };

    const getFeedbackTypes_Url = ANPS_API + "api/Feedback/GetFeedbackTypes";
    const getFeedbackTypesURL = () => getFeedbackTypes_Url;

    const getFeedbackCategory_Url =
    ANPS_API + "api/Feedback/GetFeedbackCategory";
    const getFeedbackCategoryURL = () => getFeedbackCategory_Url;

    const UploadFile_Url =
        ANPS_API + "api/Feedback/UploadFeedbackDocument?Mail={MAIL}";
    const UploadFeedbackAttachment = (Mail) => {
        return UploadFile_Url.replace("{MAIL}", Mail);
      };
    
    const AddFeedbackDetails_Url =
        ANPS_API + "api/Feedback/AddFeedbackDetails";
    const AddFeedbackDetails = () => AddFeedbackDetails_Url;

    const getURL_Suggestions = function (searchTerm) {
        return URL_Suggestions.toString() + searchTerm;
    };
    
    const getURL_Suggestions_all = function (searchTerm) {
        return URL_Suggestions_all.toString() + searchTerm;
    };
    
    const getHPENewsURL =() => `${ANPS_API}api/MyWorkSpace/GetDeltaHPENews`;

    const URL_UserTracking = `${ANPS_API}api/usertracking`;
    const getURL_UserTracking = function (AccountID) {
        return URL_UserTracking;
    };

    const get_countryCode = () => {
        return CountryCodes;
    };
    const GetSearchKeyByOpp =
    CustomerCapsuleAPI + "services/data/v38.0/sobjects/ppmc/query?q=";
    const GetSearchKeyByOppID = (SearchKey) => {
        return ApplyAuth(GetSearchKeyByOpp + SearchKey);
    };

    const URL_AccountNameSuggestions =
    DataAPIURL + "solr/account/suggest?wt=json&q=";
    const getURL_AccountNameSuggestions = function (searchTerm) {
        return URL_AccountNameSuggestions.toString() + searchTerm;
    };

    const URL_UserBookMark = ANPS_API + "api/userfeedback";
    const getURL_UserBookMark = () => URL_UserBookMark;

    const GetAcceleratorDetailsByNamePassed = (name) =>
        `${ANPS_API}api/File/GetAcceleratorDocumentByName?acceleratorName=${name}`;
    
    const ConvertDocToPdf = () =>
        `${ANPS_API}api/File/GeneratePdf`;


    const SaveNotificationToken = () =>
      `${ANPS_API}api/Notification/cp/SaveNotificationToken`;

    const SendNotificationToken = () =>
      `${CP_NOTIFICATION_API}PushNotification/SendNotification`;

    const GetUsers = () =>
      `${ANPS_API}api/Notification/cp/GetUsers`;

    const AddFeedbackAttachment = () => AddFeedbackAttachement_Url;

 const AddFeedbackAttachement_Url =
 ANPS_API + "api/Feedback/AddFeedbackAttachment";
	
	

    
    
    return {
        isOAuthLoginEnabled : isOAuthLoginEnabled,
        getURL_GetUserLoginTime,
        getURL_EmployeeAPI,
        getURL_GetChannelPartnerUser,
        get_Env,
        getURLDeltaAPI,
        ApplyAuth,
        getURLKnowledgeCapsule,
        getURLSKU,
        get_APIHost,
        getSolrBaseURL,
        isDisclosureLevel,
        isNDA,
        GetUserRoles,
        getURLReUseInsight,
        getURLReUseCapsule,
        getURLActIDOPP,
        getURLOpportunityIDByWbsID,
        getUrlOppDataByActID,
        getUrlOppDataByPRID,
        getUrlAccountIdByAccountName,
        getURL_FooterLinks,
        getNugFlixCategorisedVideoData,
        getFeedbackTypesURL,
        getFeedbackCategoryURL,
        UploadFeedbackAttachment,
        AddFeedbackDetails,
        getURL_Suggestions,
        getURL_Suggestions_all,
        getHPENewsURL,
        getURL_UserTracking,
        get_countryCode,
        GetSearchKeyByOppID,
        getURL_AccountNameSuggestions,
        getURL_UserBookMark,
        GetAcceleratorDetailsByNamePassed,
        ConvertDocToPdf,
        SaveNotificationToken,
        SendNotificationToken,
        GetUsers,
        encryptMessage,
        getnewsolrBaseURL,
        AddFeedbackAttachment,
    }
})();

export default URLConfig;