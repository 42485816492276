// import "../../css/ChannelPartner/NewCSS/responsive.css";
import React from "react";
import _ from "lodash";
import "../css/ChannelPartner/NewCSS/headsearch.css";
import hpeLogo from "../img/ChannelPartner/hpe_logo.png";
import DeltaHomeContext from "../Context/DeltaHomeContext";
import Multimedia from "../img/ChannelPartner/HeadSearch/Multimedia.jpg";
import Guides from "../img/ChannelPartner/HeadSearch/Guides.jpg";
import CustReference from "../img/ChannelPartner/HeadSearch//Customer-Reference-of-Case-Study.jpg";
import Announcemnet from "../img/ChannelPartner/HeadSearch/Announcement-and-Communication.jpg";
import BestPractices from "../img/ChannelPartner/HeadSearch/Best-Practices.jpg";
import Presentation from "../img/ChannelPartner/HeadSearch/Presentation.jpg";
import SuccessStory from "../img/ChannelPartner/HeadSearch/Success-Story.jpg";
import RefrenceArchitecture from "../img/ChannelPartner/HeadSearch/ReferenceArchitecture.jpg";
import CompetetiveInsights from "../img/ChannelPartner/HeadSearch/Competetive-Insight.jpg";
import InternalTrainings from "../img/ChannelPartner/HeadSearch/Internal-Training.jpg";
import AceeleratorsBook from "../img/ChannelPartner/HeadSearch/Accelerators.jpg";
import MarketInsights from "../img/ChannelPartner/HeadSearch/Market-Insight.jpg";
import Greenlake from "../img/ChannelPartner/HeadSearch/Greenlake.jpg";
import Github from "../img/ChannelPartner/HeadSearch/Github.jpg";
import ProcessDocuments from "../img/ChannelPartner/HeadSearch/Process-Documents.jpg";
import Demo from "../img/ChannelPartner/NewImg/Demo.png";
import Services from "../img/ChannelPartner/HeadSearch/Services.webp"
import ChannelPartnerHeadSearchModal from "./ChannelPartnerHeadSearchModal";
import logo from "../img/loading-icon-animated.gif";
import TrackingService from "../TrackingService";
import Cookies from "js-cookie";
import profile from "../img/ChannelPartner/HeadSearch/NewImg/profile.png";
import Avatar from "react-avatar";
import ChannelPartnerHeader from "./ChannelPartnerHeader";
// import ChannelHeadImg from "../img/ChannelPartner/HeadSearch/NewImg/head-main.webp"
import ChannelHeadImg from "../img/ChannelPartner/HeadSearch/NewImg/head-cp-results.webp"
import Plant1Artifact from "../img/ChannelPartner/HeadSearch/NewImg/plant-1.png";
import Pot3Artifact from "../img/ChannelPartner/HeadSearch/NewImg/pot-3.png";
import Plant2Artifact from "../img/ChannelPartner/HeadSearch/NewImg/plant-2.png";
import Pot4Artifact from "../img/ChannelPartner/HeadSearch/NewImg/pot-4.png";

import { OverlayTrigger, Tooltip, Navbar, Modal } from "react-bootstrap";
import axios from "axios";
import URLConfig from "../URLConfig";
import ReactOwlCarousel from "react-owl-carousel";
import Sidebar from "./Admin/SideBar";
const adminUsers = ["nitin-kaveriappa.udiyanda-muthanna@hpe.com","arpan.biswas@hpe.com","harimohan@hpe.com","ahalya.or-ext@hpe.com","shivani.chaurasia@hpe.com","pratiksha.shashikant-pawar@hpe.com"]

class ChannelPartnerHeadSearchResults extends React.Component {
  static contextType = DeltaHomeContext;
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.startPosition = React.createRef(0);
    this.state = {
      bookShelf: [
        { name: "multimedia", book: Multimedia },
        { name: "guides", book: Guides },
        { name: "customerreference", book: CustReference },
        { name: "announcements", book: Announcemnet },
        { name: "bestpratices", book: BestPractices },
        { name: "presentations", book: Presentation },
        { name: "successstory", book: SuccessStory },
        { name: "referencearchitecture", book: RefrenceArchitecture },
        { name: "competetiveinsights", book: CompetetiveInsights },
        { name: "internaltrainings", book: InternalTrainings },
        { name: "accelerators", book: AceeleratorsBook },
        { name: "marketinsights", book: MarketInsights },
        { name: "greenlake", book: Greenlake },
        { name: "github", book: Github },
        { name: "processdocument", book: ProcessDocuments },
        { name: "demo", book: Demo, },
        {name : "services", book : Services}
      ],

      availableShelfCategories: [],
      showModal: false,
      selectedDocumentData: {},
      isSidebarOpen:false,
      checkCategory: "",
      subcategoryhighlight: "",
      userName : "",
      rack1Artifacts : [Pot4Artifact , Plant2Artifact ],
      rack4Artifacts : [Plant1Artifact, Pot3Artifact],
      // rack1ArtifactsCss : ["54%" , "152%"],
      // rack4ArtifactsCss : ["110%", "45%"]
      rack1ArtifactsCss : ['CP-head-rack10' , 'CP-head-rack11'],
      rack4ArtifactsCss : ['CP-head-rack40', 'CP-head-rack41']
      // rack1Categories: [],
      // rack2Categories: [],
    };
  }
  findrelevancy = () => {
    var checkCategory = this.props.checkSelectedCategory;
    let arr = [];
    if (checkCategory !== undefined) {
      if (this.state.checkCategory != undefined) {
        var i;
        for (i = 0; i < checkCategory.length; i++) {
          if (checkCategory[i] == "Guides") {
            arr = [...arr, this.props.relevancyScore.guidesMaxScore];
          }
          if (checkCategory[i] == "Presentations") {
            arr = [...arr, this.props.relevancyScore.presentationsMaxScore];
          }
          if (checkCategory[i] == "Multimedia") {
            arr = [...arr, this.props.relevancyScore.multimediaMaxScore];
          }
          if (checkCategory[i] == "Training") {
            arr = [...arr, this.props.relevancyScore.trainingsMaxScore];
          }
          if (checkCategory[i] == "Other Services") {
            arr = [...arr, this.props.relevancyScore.otherservicesMaxScore];
          }
          if (checkCategory[i] == "Reuse") {
            arr = [...arr, this.props.relevancyScore.reuseMaxScore];
          }
          if (checkCategory[i] == "Service") {
            arr = [...arr, this.props.relevancyScore.servicesMaxScore];
          }
          // Commented on 11 jan 2024 blocking AcceleratorCategory
          // if (checkCategory[i] == "Accelerators") {
          //   arr = [...arr, this.props.relevancyScore.acceleratorMaxScore]
          // }
        }
        let result = _.maxBy(arr, "score");
        console.log("mostRelevant", result?.item )
        this.setState({ highlight: result?.item }, () =>
          this.highlightCategory(this.state.highlight)
        );
      }
    } else {
      arr = [
        this.props.relevancyScore.guidesMaxScore,
        this.props.relevancyScore.presentationsMaxScore,
        this.props.relevancyScore.servicesMaxScore,
        this.props.relevancyScore.trainingsMaxScore,
        this.props.relevancyScore.otherservicesMaxScore,
        this.props.relevancyScore.reuseMaxScore,
        this.props.relevancyScore.multimediaMaxScore,
        this.props.relevancyScore.acceleratorMaxScore,
      ];
      let result = _.maxBy(arr, "score");
      this.setState({ highlight: result?.item }, () =>
        this.highlightCategory(this.state.highlight)
      );
    }
  };

  componentDidMount = () => {
    console.log(" componentDidMount channelPartnerSearch", this.props);
    this.setState({ checkCategory: this.props.checkSelectedCategory });
    this.findrelevancy();
    // this.updateCategories();
    this.forceUpdate()

    if(localStorage.getItem("name")) {
      const str = localStorage.getItem("name");
      let username = (str.replace(/[^a-zA-Z ]/g, " "));
      username = username.split(" ")[0]
      this.setState({ userName : username})
    }

  };

  componentDidUpdate = (prevProps) => {
    if (!_.isEqual(prevProps.relevancyScore, this.props.relevancyScore)) {
      this.findrelevancy();
    }
    // const { onInitiateResetCompleted, isResetFlag, toggleResetFlags } = this.context;

    // if (onInitiateResetCompleted && isResetFlag) {
    //   toggleResetFlags(); // Automatically toggle flags
    // }
  };

  componentWillUnmount = () => {
    this.setState({ rack1Categories: [], rack2Categories: [], rack3Categories : [], rack4Categories : [] });
    this.componentRef = React.createRef();
    this.startPosition = React.createRef(0);
  };

  updateCarouselPosition = (object) => {
    if (object.item.index != this.startPosition.current)
      this.startPosition.current = object.item.index;
  };

  updateCategories = () => {
    console.log("updateCategories");
    const documentsList = this.props.docs;
    let searchResults = [];
    const GuidesData =
      this.props.GuidesData && this.props.GuidesData.groups?.length
        ? this.props.GuidesData
        : null;
    console.log("GuidesData", GuidesData);
    GuidesData &&
      searchResults.push({
        ...this.state.bookShelf[1],
        data: GuidesData,
        documentType: "Guides",
      });

    const PresentationsData =
      this.props.Presentations && this.props.Presentations.groups?.length
        ? this.props.Presentations
        : null;
    console.log("PresentationsData", PresentationsData);
    PresentationsData &&
      searchResults.push({
        ...this.state.bookShelf[5],
        data: PresentationsData,
        documentType: "Presentations",
      });

    const ServicesData =
      this.props.ServicesData && this.props.ServicesData.groups?.length
        ? this.props.ServicesData
        : null;
    console.log("ServicesData", ServicesData);
    ServicesData &&
      searchResults.push({
        ...this.state.bookShelf[16],
        data: ServicesData,
        documentType: "Services",
      });

    const MultimediaData =
      this.props.MultimediaData && this.props.MultimediaData.groups?.length
        ? this.props.MultimediaData
        : null;

    console.log("MultimediaData", MultimediaData);
    MultimediaData &&
      searchResults.push({
        ...this.state.bookShelf[0],
        data: MultimediaData,
        documentType: "Multimedia",
      });

    const POCList =
      this.props.POCList && this.props.POCList?.length
        ? this.props.POCList
        : null;
    console.log("POCList", POCList);
    POCList &&
      searchResults.push({
        ...this.state.bookShelf[0],
        data: POCList,
        documentType: "POC Central",
      });

    const TrainingsData =
      this.props.TrainingsData && this.props.TrainingsData.groups?.length
        ? this.props.TrainingsData
        : null;
    console.log("TrainingsData", TrainingsData);
    TrainingsData &&
      searchResults.push({
        ...this.state.bookShelf[9],
        data: TrainingsData,
        documentType: "Internal Trainings",
      });

    const AcceleratorData = this.props.acceleratorData
      ? this.props.acceleratorData
      : null;
    console.log("AcceleratorData", AcceleratorData);
    AcceleratorData &&
      searchResults.push({
        ...this.state.bookShelf[11],
        data: AcceleratorData,
        documentType: "Aceelerators",
      });

    const greenLakeObject = documentsList?.find((list) => {
      return list.groupValue === "Greenlake";
    });
    console.log("greenLakeObject", greenLakeObject);
    greenLakeObject &&
      searchResults.push({
        ...this.state.bookShelf[12],
        data: greenLakeObject,
        documentType: "Greenlake",
      });

    const acceleratorsObject = documentsList?.find((list) => {
      return list.groupValue === "Accelerators";
    });
    console.log("acceleratorsObject", acceleratorsObject);
    acceleratorsObject &&
      searchResults.push({
        ...this.state.bookShelf[10],
        data: acceleratorsObject,
        documentType: "Accelerators",
      });
    const githubObject = documentsList?.find((list) => {
      return list.groupValue === "Github";
    });
    console.log("githubObject", githubObject);
    githubObject &&
      searchResults.push({
        ...this.state.bookShelf[13],
        data: githubObject,
        documentType: "Github",
      });

    const referenceArchitecturesObject = documentsList?.find((list) => {
      return list.groupValue === "Reference Architectures";
    });
    console.log("referenceArchitecturesObject", referenceArchitecturesObject);
    referenceArchitecturesObject &&
      searchResults.push({
        ...this.state.bookShelf[7],
        data: referenceArchitecturesObject,
        documentType: "Reference Architectures",
      });

    const marketInsightsObject = documentsList?.find((list) => {
      return list.groupValue === "Market Insights";
    });
    console.log("marketInsightsObject", marketInsightsObject);
    marketInsightsObject &&
      searchResults.push({
        ...this.state.bookShelf[11],
        data: marketInsightsObject,
        documentType: "Market Insights",
      });

    const successStoryObject = documentsList?.find((list) => {
      return list.groupValue === "Success Story";
    });
    console.log("successStoryObject", successStoryObject);
    successStoryObject &&
      searchResults.push({
        ...this.state.bookShelf[6],
        data: successStoryObject,
        documentType: "Success Story",
      });

    const announcementsAndCommunicationsObject = documentsList?.find((list) => {
      return list.groupValue === "Announcements and Communications";
    });
    console.log(
      "announcementsAndCommunicationsObject",
      announcementsAndCommunicationsObject
    );
    announcementsAndCommunicationsObject &&
      searchResults.push({
        ...this.state.bookShelf[3],
        data: announcementsAndCommunicationsObject,
        documentType: "Announcements and Communications",
      });

    const customerReferencesOrCaseStudyObject = documentsList?.find((list) => {
      return list.groupValue === "Customer References or Case Study";
    });
    console.log(
      "customerReferencesOrCaseStudyObject",
      customerReferencesOrCaseStudyObject
    );
    customerReferencesOrCaseStudyObject &&
      searchResults.push({
        ...this.state.bookShelf[2],
        data: customerReferencesOrCaseStudyObject,
        documentType: "Customer References or Case Study",
      });

    const competitiveInsightsObject = documentsList?.find((list) => {
      return list.groupValue === "Competitive Insights";
    });
    console.log("competitiveInsightsObject", competitiveInsightsObject);
    competitiveInsightsObject &&
      searchResults.push({
        ...this.state.bookShelf[8],
        data: competitiveInsightsObject,
        documentType: "Competitive Insights",
      });

    const bestPracticesObject = documentsList?.find((list) => {
      return list.groupValue === "Best Practices";
    });
    console.log("bestPracticesObject", bestPracticesObject);
    bestPracticesObject &&
      searchResults.push({
        ...this.state.bookShelf[4],
        data: bestPracticesObject,
        documentType: "Best Practices",
      });

    const processDocumentsObject = documentsList?.find((list) => {
      return list.groupValue === "Process Documents";
    });
    console.log("processDocumentsObject", processDocumentsObject);
    processDocumentsObject &&
      searchResults.push({
        ...this.state.bookShelf[14],
        data: processDocumentsObject,
        documentType: "Process Documents",
      });

    const demoObject = documentsList?.find((list) => {
      return list.groupValue === "Demo";
    });
    console.log("processDocumentsObject", demoObject);
    demoObject &&
      searchResults.push({
        ...this.state.bookShelf[15],
        data: demoObject,
        documentType: "Demo",
      });

    console.log("searchResultssearchResults", searchResults);
    let rackFirstIndex = 0;
    let rackSecondIndex = 0;
    // let updatedSearchResults = searchResults.map((item, index) => {
    //         rackFirstIndex = rackFirstIndex >= 6 ? 1 : rackFirstIndex + 1
    //         rackSecondIndex = rackFirstIndex === 1 ? rackSecondIndex + 1 : rackSecondIndex;
    //         return {
    //             ...item,
    //             rackIndex : rackFirstIndex.toString() + rackSecondIndex.toString()
    //     }
    // })
    let updatedSearchResults = [];
    let updatedRack1Categories = [];
    let updatedRack2Categories = [];
    let updatedRack3Categories = [];
    let updatedRack4Categories = [];

    if(searchResults <= 8) {
      updatedSearchResults = searchResults.map((item, index) => {
        rackFirstIndex = rackFirstIndex >= 2 ? 1 : rackFirstIndex + 1;
        return {
          ...item,
          rackIndex: rackFirstIndex,
        };
      });
      updatedRack2Categories = updatedSearchResults.filter((data) => {
        return data.rackIndex === 1;
      });
      updatedRack3Categories = updatedSearchResults.filter((data) => {
        return data.rackIndex === 2;
      });
    }
    else {
      let newSearchResults = [...searchResults];
      let extraSearchResultsCount = searchResults.length - 8;
      let remainingSearchResults = newSearchResults.splice(newSearchResults.length - extraSearchResultsCount,
        extraSearchResultsCount);
        console.log("newSearchResultsnewSearchResultsnewSearchResults", searchResults)
        updatedSearchResults = newSearchResults.map((item, index) => {
          rackFirstIndex = rackFirstIndex >= 2 ? 1 : rackFirstIndex + 1;
          return {
            ...item,
            rackIndex: rackFirstIndex,
          };
        });
        updatedRack2Categories = updatedSearchResults.filter((data) => {
            return data.rackIndex === 1;
          });
        updatedRack3Categories = updatedSearchResults.filter((data) => {
            return data.rackIndex === 2;
        });
        let newRackFirstIndex = 0;

        let updatedRemainingSearchResults = remainingSearchResults.map((item , index) => {
          newRackFirstIndex = newRackFirstIndex >= 2 ? 1 : newRackFirstIndex +1;
          return {
            ...item,
            rackIndex: newRackFirstIndex,
          };
        })

        updatedRack1Categories = updatedRemainingSearchResults.filter((data) => {
          return data.rackIndex === 1;
        });
        updatedRack4Categories = updatedRemainingSearchResults.filter((data) => {
            return data.rackIndex === 2;
        });
    }

    let newRack1Categories = [...updatedRack1Categories];
    if(updatedRack1Categories.length < 2) {
      let artifacts = this.state.rack1Artifacts;

      artifacts.map((item,index) => {
        if(newRack1Categories.length == 2) {
          return;
        }
        newRack1Categories.push({
          book : null,
          Artifact : item,
          className : this.state.rack1ArtifactsCss[index]
        })
        
      })
    }

    let newRack4Categories = [...updatedRack4Categories];
    if(updatedRack4Categories.length < 2) {
      let artifacts = this.state.rack4Artifacts;

      artifacts.map((item, index) => {
        if(newRack4Categories.length == 2) {
          return;
        }
        newRack4Categories.push({
          book : null,
          Artifact : item,
          className : this.state.rack4ArtifactsCss[index]
        })
      })
    }
    
    console.log("updatedSearchResults", newRack4Categories);
    return {
      rack1Categories: newRack1Categories,
      rack2Categories: updatedRack2Categories,
      rack3Categories : updatedRack3Categories,
      rack4Categories : newRack4Categories
    };
  };

  handleBookClick = async (bookItem, isShowModal = true) => {
    console.log("bookItembookItem", bookItem);

    this.setState({ showModal: true, selectedDocumentData: bookItem });
  };

  showHideModal = () => {
    console.log("showHideModal");
    this.setState({ showModal: !this.state.showModal });
  };

  logDocClick = (documentDetails) => {
    console.log("logDocClick");
    // this.setState({ documentDetailsAll: documentDetails });
    if (!this.TrackingService) {
      this.TrackingService = new TrackingService();
    }
    this.TrackingService.OpenLink(Cookies.get("empnumber"), documentDetails);
  };

  handleOpenSideBar =()=>{
    this.setState({
      isSidebarOpen:true
    })

  }

  handleCloseSideBar =()=>{
    this.setState({
      isSidebarOpen:false
    })

  }



  highlightCategory = (categoryname) => {
    switch (categoryname) {
      case "Reuse":
        this.setState({
          isReuse: true,
          reusebg: "#0d5265",
          highlight:
            this.props.relevancyScore.reuseMaxScore.subitem,
        });
        break;
      case "Guides":
        this.setState({
          isGuides: true,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isReuse: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.guidesMaxScore.subitem,
        });
        break;
      case "Presentations":
        this.setState({
          isPresentation: true,
          isServices: false,
          isMultimedia: false,
          isReuse: false,
          isGuides: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.presentationsMaxScore.subitem,
        });
        break;
      case "Services":
        this.setState({
          isServices: true,
          isMultimedia: false,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.servicesMaxScore.subitem,
        });
        break;
      case "Multimedia":
        this.setState({
          isMultimedia: true,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.multimediaMaxScore.subitem,
        });
        break;
      case "Reuse":
        this.setState({
          isReuse: true,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight: this.props.relevancyScore.reuseMaxScore.subitem,
        });
        break;
      case "Other Services":
        this.setState({
          isOtherServices: true,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.otherservicesMaxScore.subitem,
        });
        break;
      case "Internal Trainings":
        this.setState({
          isOtherServices: false,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isInternalTraining: true,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.trainingsMaxScore.subitem,
        });
        break;
      // Commented on 11 jan 2024 as blocking AcceleratorCategory component
      // case "Accelerators":
      //   this.setState({
      //     isOtherServices: false,
      //     isReuse: false,
      //     isGuides: false,
      //     isPresentation: false,
      //     isServices: false,
      //     isMultimedia: false,
      //     isInternalTraining: false,
      //     isAccelerator:true,
      //     subcategoryhighlight:
      //       this.props.relevancyScore.acceleratorMaxScore.subitem,
      //   })
      //   break;
      default:
        this.setState({
          isOtherServices: false,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight: "",
        });
    }
  };

  render() {
    // this.updateCategories();
    const { rack1Categories, rack2Categories, rack3Categories, rack4Categories } = this.updateCategories();
    return (
      <>
        <div id="content" className="cp_header-main">
          <header className="cp_header">
            <div className="col-12 row px-3 d-flex align-items-center justify-content-center cp_header_col">
              <div className="col-1">
                {/* <img src="images/hpe_logo.png" width="120" alt=""/> */}
                <Navbar.Brand href="/">
                  <img
                    className="headSearchResult-navbar-img"
                    src={hpeLogo}
                    width="120"
                    height="50"
                    alt=""
                  />{" "}
                </Navbar.Brand>
              </div>
              <div className="col-10 cp_header-text">
                <p className="cp_header-text_1">
                  Hello <span className='text-capitalize'>{this.state.userName}</span>! Welcome to the
                  Digital Library
                </p>
              </div>
              
              <div className="col-1 cp_header-profile">
              {adminUsers.includes(localStorage.getItem("mail")) &&
              <i class="fa-xs fa fa-cog headSearchResult-header-profile" onClick={this.handleOpenSideBar}></i>
  }
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={
                    <Tooltip className=" homepage-profile-tooltip">
                      <>
                        <div>
                          {Cookies.get("empnumber") !== undefined
                            ? Cookies.get("name")
                            : "User"}
                        </div>
                      </>
                    </Tooltip>
                  }
                >
                  <div>
                    <Avatar
                      // onClick={() => handleClickUserProfile()}
                      className="homepage-profile-avatar"
                      color={Avatar.getRandomColor("sitebase", [
                        "red",
                        "green",
                        "blue",
                      ])}
                      name={
                        Cookies.get("empnumber") !== undefined
                          ? Cookies.get("name")
                          : "User"
                      }
                      title=""
                      size="35"
                      round
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </header>
{console.log(this.context , rack1Categories , rack2Categories , rack3Categories , rack4Categories)}
{this.context.onInitiateSearchCompleted && rack2Categories.length ===0 && rack3Categories.length ===0  ?(
  <>
{console.log(this.context)}
{this.context.onInitiateResetCompleted  && this.context.isResetFlag ? (
  <div className="pt-3 mx-auto">
<button className="ChannelPartner-centerDiv shadow">
  <div className="titlealign">Reset Completed</div> 
</button>

</div>
):(
<div className="pt-3 mx-auto">
<button className="ChannelPartner-centerDiv shadow">
  <div className="titlealign">No Results Found</div> 
</button>

</div>
)

}



</>
):(<>
          <div align="center" className="cp_searchhead-main">
            <div
              id="head"
              // className="cp_searchhead-head"
            >
                  <img src={ChannelHeadImg} className="headSearchResult-head-img"/>
                  <div className="headSearchResult-head">                
              {this.context.showLoader ? (
                <div
                  className="text-center headSearchResult-loader"
                >
                  <img className="loading-img" src={logo} alt="loading"></img>
                </div>
              ) : (
                <>
                 <div
                    // key={index}
                    className={
                      "cp_searchhead-rack_1 col-12 row CP-head-books-gap "
                    }
                    // id="rack1"
                    // className={"cp_searchhead-rack_"+ list.rackIndex + " cp_searchhead_img-"+list.name} id="rack1"
                  >
                    {rack1Categories.map((list, index) => {
                          return (
                            <>
                            {list.book ? 
                            <div
                            className="CP-head-book1 cp_searchhead-rackbook col"
                            onClick={() => this.handleBookClick(list)}
                            id={index}>
                            <div className="CP-head-book-wrap">
                              <div className="CP-head-book-items">
                                <div className="CP-head-main-book-wrap">
                                {(this.state.highlight?.toLowerCase() === list.documentType?.toLowerCase() || this.state.subcategoryhighlight.toLowerCase() === list.documentType.toLowerCase())&& (
                                    <div className={`rim1 ${list.documentType}`}></div>)}
                                  <div className="CP-head-book-cover">
                                    <div className="CP-head-book-inside"></div>
                                    <div className="CP-head-book-image">
                                      <img src={list.book} />
                                      <div className="CP-head-effect"></div>
                                      <div className="CP-head-light"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div> : 
                            <div
                            className="CP-head-book1 cp_searchhead-rackbook col"
                            id={index}>
                            <div className="CP-head-book-wrap">
                              <div className="CP-head-book-items">
                                <div className="CP-head-main-book-wrap">
                                  <div className="CP-head-book-cover">
                                    <div>
                                      <img src={list.Artifact} className={list['className']}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>}
                        </>
                            
                        );
                    })}
                  </div>
                  <div
                    // key={index}
                    className={
                      "cp_searchhead-rack_2 col-12 row CP-head-books-gap "
                    }
                    // id="rack1"
                    // className={"cp_searchhead-rack_"+ list.rackIndex + " cp_searchhead_img-"+list.name} id="rack1"
                  >
                    {rack2Categories.map((list, index) => {
                          return (
                            <div
                              className="CP-head-book1 cp_searchhead-rackbook col"
                              onClick={() => this.handleBookClick(list)}
                              id={index}
                            >
                              <div className="CP-head-book-wrap">
                                <div className="CP-head-book-items">
                                  <div className="CP-head-main-book-wrap">
                                  {(this.state.highlight?.toLowerCase() === list.documentType?.toLowerCase() || this.state.subcategoryhighlight.toLowerCase() === list.documentType.toLowerCase())&& (
                                    <div className={`rim1 ${list.documentType}`}></div>)}
                                    <div className="CP-head-book-cover">
                                      <div className="CP-head-book-inside"></div>
                                      <div className="CP-head-book-image">
                                        <img src={list.book} />
                                        <div className="CP-head-effect"></div>
                                        <div className="CP-head-light"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                    })}
                  </div>
                  <div
                    // key={index}
                    className={
                      "cp_searchhead-rack_3 col-12 row CP-head-books-gap "
                    }
                    // id="rack1"
                    // className={"cp_searchhead-rack_"+ list.rackIndex + " cp_searchhead_img-"+list.name} id="rack1"
                  >
                    {rack3Categories.length > 0 ? (
                      <>
                        {rack3Categories.map((list, index) => {
                          return (
                            <div
                              className="CP-head-book1 cp_searchhead-rackbook col"
                              onClick={() => this.handleBookClick(list)}
                              id={index}
                            >
                              <div className="CP-head-book-wrap">
                                <div className="CP-head-book-items">
                                  <div className="CP-head-main-book-wrap">

                                  {(this.state.highlight?.toLowerCase() === list.documentType?.toLowerCase() || this.state.subcategoryhighlight.toLowerCase() === list.documentType.toLowerCase())&& (
                                    <div className={`rim1 ${list.documentType}`}></div>)}
                                    <div className="CP-head-book-cover">
                                      <div className="CP-head-book-inside"></div>
                                      <div className="CP-head-book-image">
                                        <img src={list.book} />
                                        <div className="CP-head-effect"></div>
                                        <div className="CP-head-light"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    // key={index}
                    className={
                      "cp_searchhead-rack_4 col-12 row CP-head-books-gap "
                    }
                    // id="rack1"
                    // className={"cp_searchhead-rack_"+ list.rackIndex + " cp_searchhead_img-"+list.name} id="rack1"
                  >
                    {rack4Categories.length > 0 ? (
                      <>
                        {rack4Categories.map((list, index) => {
                          return (
                            <>
                            {list.book ?
                            <div
                              className="CP-head-book1 cp_searchhead-rackbook col"
                              onClick={() => this.handleBookClick(list)}
                              id={index}
                            >
                              <div className="CP-head-book-wrap">
                                <div className="CP-head-book-items">
                                  <div className="CP-head-main-book-wrap">

                                  {(this.state.highlight?.toLowerCase() === list.documentType?.toLowerCase() || this.state.subcategoryhighlight.toLowerCase() === list.documentType.toLowerCase())&& (
                                    <div className={`rim1 ${list.documentType}`}></div>)}
                                    <div className="CP-head-book-cover">
                                      <div className="CP-head-book-inside"></div>
                                      <div className="CP-head-book-image">
                                        <img src={list.book} />
                                        <div className="CP-head-effect"></div>
                                        <div className="CP-head-light"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            : 
                            <div
                            className="CP-head-book1 cp_searchhead-rackbook col"
                            id={index}>
                            <div className="CP-head-book-wrap">
                              <div className="CP-head-book-items">
                                <div className="CP-head-main-book-wrap">
                                  <div className="CP-head-book-cover">
                                    <div>
                                      <img src={list.Artifact} className={list['className']}/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>}
                            </>
                            
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
</div>
              
            </div>
          </div>
          </>)}
          <div
            align="center"
            id="footer"
            className="headSearchResult-footer"
          >
            © Copyright 2024 Hewlett Packard Enterprise Development LP
          </div>
        </div>
        {this.state.showModal && (
          <ChannelPartnerHeadSearchModal
            show={this.state.showModal}
            documentDetails={this.state.selectedDocumentData}
            showHideModal={this.showHideModal}
            filters={this.props.filters}
            searchTerm={this.props.searchTerm}
            logDocClick={this.logDocClick}
          />
        )}
       <Sidebar
       show={this.state.isSidebarOpen}
       handleClose={this.handleCloseSideBar}
       />
      </>
    );
  }
}

export default ChannelPartnerHeadSearchResults;
