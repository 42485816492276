import React, { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import URLConfig from "../URLConfig";

const FileDownload = ({ doc, logDocClick }) => {
  const [fileLoader, setFileLoader] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const handleDirectDownload = async (e, doc) => {
    logDocClick();
    e.preventDefault();
    setFileLoader(true);
    const URL = `https://hpedelta.com/viewer.aspx?requestType=EV`;
    const files = [];

    if(doc.url.includes("https://hpedelta.com:8082/")){
      files.push(
        "F:\\AnPS\\Sharepointfiles\\" +
          doc.url
            .replace("https://hpedelta.com:8082/", "")
            .replace(/ /g, "%20")
            .split("/")
            .join("\\")
      );
    }
    else if(doc.url.includes("https://hpedelta.com:8083/")){
      files.push(
        "F:\\AnPS\\CampaignBanner\\" +
          doc.url
            .replace("https://hpedelta.com:8083", "")
            .replace(/ /g, "%20")
            .split("/")
            .join("\\")
      );
    }

    if (files.length > 0) {
      try {
        const response = await axios.post(
          URL,
          { files },
          {
            responseType: "blob",
            onDownloadProgress: (progressEvent) =>
              setDownloadProgress(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              ),
          }
        );
        if (files.length > 0 && files.length == 1) {
          // let fileTypeURL = URLConfig.GetAcceleratorDetailsByNamePassed(files[0].replace(/^.*[\\\/]/, "").replace(/%20/g, " "))
          // const fileType = await axios.get(fileTypeURL)
          // if(fileType.data) {
          //   const pdfURL = URLConfig.ConvertDocToPdf();
          //   const fileName = files[0].replace(/^.*[\\\/]/, "").replace(/%20/g, " ").replace(/docx/, "pdf")
          //   const formData = new FormData();
          //   formData.append("fileName", fileName);
          //   formData.append("blob", response.data);
          //   const res = axios.post(pdfURL, formData, {
          //     responseType: "blob",
          //   }).then((result) => {
          //     saveAs(result.data, fileName);
          //   }).catch((error) => {
          //     console.log("Error downloading file - " + error)
          //   });
          // } else {
            saveAs(
              response.data,
              files[0].replace(/^.*[\\\/]/, "").replace(/%20/g, " ")
            );
          // }
        } else if (files.length > 1) {
          saveAs(response.data, `Download-${new Date().toISOString()}.zip`);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setFileLoader(false);
  };

  return (
    <>
      <a
        href=""
        className="breakall_word a-hover channel-partner-download_file"
        onClick={
          fileLoader
            ? (e) => e.preventDefault()
            : (e) => handleDirectDownload(e, doc)
        }
      >
        {doc.file}
        {doc.isarchived && <span className="archivedDocs">A</span>}
        {doc.isgoldcollateral && <span className="goldCol">G</span>}
      </a>
      <div>
      {fileLoader && !isNaN(downloadProgress) ? `Downloading...${downloadProgress}%` : ""}
      </div>
    </>
  );
};

export default FileDownload;
