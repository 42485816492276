import {React, useEffect , useState} from "react";
import { Button, Modal, Row, CloseButton, Col } from "react-bootstrap";
import Documents from "./Document";
import Pagination from "react-js-pagination";
import URLConfig from "../URLConfig";
import axios from "axios";
import Cookies from "js-cookie";
import bookModal from "../img/ChannelPartner/NewImg/book-bg.webp";
import logo from "../img/loading-icon-animated.gif";

const ChannelPartnerHeadSearchModal = ({
  show,
  documentDetails,
  showHideModal,
  filters,
  searchTerm,
  logDocClick
}) => {
    const [searchType , setSearchType] = useState("");
    const [isLoading , setIsLoading] = useState(true);
    const [activeDocType , setActiveDocType] = useState("");
    const [filtersApplied , setFiltersApplied] = useState(filters ? filters :"&fq=-isarchived:True");

    // const [searchTerm , setSearchTerm] = useState("");
    const [documentList , setDocumentList] = useState([]);
    const [popupHeader , setPopupHeader] = useState("");
    const [activePage , setActivePage] = useState(1);
    const [totalItemsCount , setTotalItemsCount] = useState(0);
    const [showMore , setShowMore] = useState(false);
    const [searchKey , setSearchKey] = useState("");
    const [itemsCountPerPage , setItemsCountPerPage] = useState(4);
    // const [currentSelectedSubCategories,setCurrentSelectedSubCategories] = useState(false)
    const [showForm, setShowForm] = useState(false);

    useEffect(()=>{
      
      const img = new Image()
      img.onload=()=>{
        setShowForm(true)
      }
      
      img.src =bookModal
      
        },[])

    useEffect(() => {
        console.log("selectedDocumentData", documentDetails , searchTerm)
        if(documentDetails && documentDetails.data.groups) {
            setFiltersApplied(filters ? filters : "&fq=-isarchived:True")
            setSearchType("category");
            // setActiveDocType(documentDetails.data.groups[0].groupValue)
            ShowMore(documentDetails.data.groups[0]?.groupValue)
        }
        else {
            setFiltersApplied(filters ? filters : "&fq=-isarchived:True")
            setSearchType("others");
            ShowMoreForOthers(documentDetails.documentType)
        }
    },[JSON.stringify(documentDetails)]);

    // useEffect(() => {
    //     if(activeDocType)
    //         ShowMore(activeDocType);
    // },[activeDocType])


    const ShowMore = (categoryName) => {
        setIsLoading(true);
        setActiveDocType(categoryName)
        console.log("activeDocTypeactiveDocType", categoryName)
        // const modifiedSearchKey = searchTerm.replace(/-/g, "%20");
        const modifiedSearchKey = searchTerm.replace(/[!#$%&()*+,-./;<=>?@[\]^`{|}~]+/g, " ");
        const URLGuides =
          URLConfig.get_APIHost() +
          // "solr/sharepoint_index/select?fl=file,url,isgoldcollateral,rating,disclosure_level,title,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22Presentations%22%20AND%20document_type_details:%22" +
          URLConfig.getSolrBaseURL()+"/select?fl=file,url,isgoldcollateral,rating,"+URLConfig.isDisclosureLevel()+"title,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22"
          +documentDetails.documentType+"%22%20AND%20document_type_details:%22" +
          categoryName +
          "%22&indent=on&q=" +
          modifiedSearchKey +
          "&&rows=4&start=0&wt=json" +
          (filtersApplied.indexOf('&fq=nda:"True"') === -1
            // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            ? URLConfig.isNDA(true) + URLConfig.GetUserRoles()
            : "") +
            filtersApplied;
            // const payload = URLConfig.encryptMessage(URLGuides)

        // axios.get(URLGuides)
        console.log(URLGuides,"URLGuides")
        URLConfig.getnewsolrBaseURL(URLGuides)
        .then((res) => {
          if (res.data.response) {
            setDocumentList(res.data.response);
            setPopupHeader(categoryName);
            setActivePage(1);
            setTotalItemsCount(res.data.response.numFound);
            setShowMore(true);
            setSearchKey("")
          }
          setIsLoading(false);
        });
    };


    const ShowMoreForOthers = (categoryName) =>{
        setActiveDocType(categoryName)
        setIsLoading(true);
    const searchWord = document
      .getElementsByClassName("react-autosuggest__input")[0]
      .value.replace(/[#?&@]/g, " ")
      .replace(/-/g, "%20");

      const modifiedSearchKey = searchTerm.replace(/[!#$%&()*+,-./;<=>?@[\]^`{|}~]+/g, " ");

    const url =
      URLConfig.get_APIHost() +
      // "solr/sharepoint_index/select?fl=id,file%2curl%2crating%2cdisclosure_level%2cdoc_source%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22Service Capsule%22 AND document_type:%22" +
      URLConfig.getSolrBaseURL() +
      "/select?fl=id,file%2curl%2crating%2c" +
      URLConfig.isDisclosureLevel(true) +
      "doc_source%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22Service Capsule%22 AND document_type:%22" +
      categoryName +
      "%22&indent=on&q=" +
      modifiedSearchKey +
      "&rows=4&start=0&wt=json" +
      URLConfig.GetUserRoles() +
      filtersApplied;
    // axios.get(url)
    URLConfig.getnewsolrBaseURL(url)
    .then((res) => {
      if (res.data.response.docs) {
        setDocumentList(res.data.response);
        setPopupHeader(categoryName);
        setActivePage(1);
        setTotalItemsCount(res.data.response.numFound);
        setShowMore(true);
        setSearchKey("");

        // this.setState({
        //   popupHeader: categoryName,
        //   moreData: res.data.response,
        //   InitialData: res.data.response,
        //   activePage: 1,
        //   totalItemsCount: res.data.response.numFound,
        //   showMore: true,
        //   searchKey: "",
        // });
      }
      setIsLoading(false);
    });
    }

    const updateActiveDocType = (category) => {
        setActiveDocType(category);
        ShowMore(category);
    }

    const handleSearchValueChange = (event) => {
        const searchValue = event.target.value;
        setSearchKey(searchValue);
    }

    const fireSearch = (event) => {
        if (event.keyCode === 13) {
          // Enter key
          handlePageChange(1);
        }
    };

    const handlePageChange = (pageNumber , search = searchKey) => {
        console.log("222222", searchType)
        if(searchType === "category") {
            fetchMore(pageNumber , search);
        }
        else {
            fetchMoreForOthers(pageNumber , search)
        }
        
        setActivePage(pageNumber)
        // if(this.componentRef.current !==null) { 
        // this.componentRef.current.scrollTop = 0;
        // }
    };


    const fetchMore = (
        pageNumber = activePage,
        searchKey = searchKey
      ) => {
        setIsLoading(true);
        const begin =
          pageNumber * itemsCountPerPage - itemsCountPerPage;
          // const modifiedSearchKey = searchTerm.replace(/-/g, "%20");
        const modifiedSearchKey = searchTerm.replace(/[!#$%&()*+,-./;<=>?@[\]^`{|}~]+/g, " ");

        const url =
          URLConfig.get_APIHost() +
          // "solr/sharepoint_index/select?fl=file,url,isgoldcollateral,rating,disclosure_level,title,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22Presentations%22%20AND%20document_type_details:%22" +
          URLConfig.getSolrBaseURL()+"/select?fl=file,url,isgoldcollateral,rating,"+URLConfig.isDisclosureLevel()+"title,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22"
          +documentDetails.documentType+"%22%20AND%20document_type_details:%22" +
          activeDocType +
          "%22&indent=on&q=" +
          modifiedSearchKey +
          "&&rows=4&start=" +
          begin +
          "&wt=json" +
          (filtersApplied.indexOf('&fq=nda:"True"') === -1
            // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
            ? URLConfig.isNDA(true) + URLConfig.GetUserRoles()
            : "") +
            filtersApplied +
          (searchKey ? `&fq=file:%22${searchKey}%22` : "");
        // axios.get(url)
        URLConfig.getnewsolrBaseURL(url)
        .then((res) => {
          if (res.data.response.docs) {
            setDocumentList(res.data.response);
            setTotalItemsCount(res.data.response.numFound)
          }
          setIsLoading(false);

        });
        setActivePage(pageNumber)
        // if(this.componentRef.current !==null) { 
        //  this.componentRef.current.scrollTop = { top: 0, behavior: "smooth" };
        // }
    };


    const fetchMoreForOthers = (
        pageNumber = activePage,
        searchKey = searchKey
      ) => {
        const begin =
          pageNumber * itemsCountPerPage - itemsCountPerPage;
        // const searchWord = document
        //   .getElementsByClassName("react-autosuggest__input")[0]
        //   .value.replace(/[#?&@]/g, " ")
        //   .replace(/-/g, "%20");

        // const searchWord = searchTerm.replace(/-/g, "%20");
        const searchWord = searchTerm.replace(/[!#$%&()*+,-./;<=>?@[\]^`{|}~]+/g, " ");

        setIsLoading(true);
        const url =
          URLConfig.get_APIHost() +
          // "solr/sharepoint_index/select?fl=id,file%2curl%2crating%2cdisclosure_level%2cdoc_sourcee%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22Service Capsule%22 AND document_type:%22" +
          URLConfig.getSolrBaseURL() +
          "/select?fl=id,file%2curl%2crating%2c" +
          URLConfig.isDisclosureLevel(true) +
          "doc_sourcee%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22Service Capsule%22 AND document_type:%22" +
          activeDocType +
          "%22&indent=on&q=" +
          searchWord +
          "&rows=4&start=" +
          begin +
          "&wt=json" +
          URLConfig.GetUserRoles() +
          filtersApplied +
          (searchKey ? `&fq=file:%22${searchKey}%22` : "");
        // axios.get(url)
        URLConfig.getnewsolrBaseURL(url)
        .then((res) => {
          if (res.data.response.docs) {
            // this.setState({
            //   moreData: res.data.response,
            //   InitialData: res.data.response,
            //   totalItemsCount: res.data.response.numFound,
            // });
            setDocumentList(res.data.response);
            setTotalItemsCount(res.data.response.numFound)
          }
          setIsLoading(false);
        });
        // this.setState({ activePage: pageNumber });
        setActivePage(pageNumber)
        
        // this.componentRef.current.scrollTop = 0;
      };

    const resetResults = () => {
        // this.setState({ searchKey: "" }, () => this.handlePageChange(1));
        setSearchKey("");
        handlePageChange(1 , "")
    };

  return (
    <>
    {
      showForm?(
    <div>
    <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="channel-partner-modal delta-new-search-result-page-documentModal"
        
        animation={false}
        // onHide={() => handleClose()}
      >
        {/* <Modal.Title>Help Videos</Modal.Title> */}
     
        <Modal.Header className="headSearch-header">
              <Row
                className="justify-content-end delta-new-search-result-page-close-row"
              >
                <div className="delta-new-search-result-page-close">
                  <CloseButton
                    className="headSearch-close-button"
                    onClick={() => showHideModal()}
                  />
                </div>
              </Row>
            </Modal.Header>
       
          <Modal.Body className="pb-0 headSearch-padding">
                <Row className="delta-new-search-result-page-modal-body">
                  <Col
                    className="pt-2 pb-2 col-6 delta-new-search-result-page-modal-body-title-container headSearch-col"
                  >
                    <div
                      id="maincontainer"
                      className="DS-head-Modal-MainContainer headSearch-maincontainer"
                    >
                      <h1
                        className={
                          documentDetails.data.groups?.length > 0 
                            ? (documentDetails.data.groups?.length > 4) ?
                             "ds_searchhead_modal-h1" : "ds_searchhead_modal-h1-less"
                            : "ds_searchhead_modalwithoutlist-h1"
                        }
                      >
                        {documentDetails.documentType}
                        {/*   
                      <span className="circle-count">
            <span className="count-inner">  {documentList.documentType === "POC Central" || documentList.documentType === "SKU" 
             ? documentDetails.data?.length 
             :( documentDetails.data.groups?.length > 0
                          ? documentDetails.data?.matches : documentDetails.data.doclist.numFound)}</span>
          </span> */}
                      </h1>
                      <div
                        className="ds_searchhead_modal-index-section headSearch-indexsection"
                      >
                        <div className="ds_searchhead_modal-index-subsection">
                          {searchType === "category" && (
                            <>
                              {documentDetails.data.groups.map(
                                (type, index) => (
                                  <>
                                    <h3
                                      role="button"
                                      className={
                                        "border-bottom pb-3 ds-searchhead-subcategory-list text-capitalize" +
                                        (activeDocType === type.groupValue
                                          ? " delta-new-search-page-highlight"
                                          : " delta-new-search-page-no-highlight") /* +
                                      (subcategoryhighlight === type.groupValue
                                        ? " subcatbg"
                                        : "") */
                                      }
                                      onClick={() =>
                                        updateActiveDocType(type.groupValue)
                                      }
                                    >
                                      {type.groupValue}
                                      {/*  <span className="circle-count">
            <span className="count-inner">{type.doclist.numFound}</span>
          </span> */}
                                    </h3>
                                   
                                  </>
                                )
                              )}
                            </>
                          ) }
                        </div>
                      </div>
                    </div>
                  </Col>
                  {
                 (
                <>
                  { (
                    <Col
                      className="DS-head-bmodal-body-col headSearch-DS-head-bmodal"
                    >
                      <>
                        <div className="row col-12 pb-4 DS-head-bmodal-body-col-header">
                          <div className="col-10 input-wrapper">
                            <input
                              className="delta-new-search-page-form-control-font-size p-1"
                              // form-control form-control-sm ms-3 mb-3 
                              type="text"
                              value={searchKey}
                              onChange={handleSearchValueChange}
                              onKeyUp={fireSearch}
                              placeholder="Search File Name"
                            />
                          </div>
                          <span
                            className="col-2 reset-wrapper"
                            title="Click to reset current search."
                          >
                            <i
                              className="fas fa-sync-alt reset-btn"
                              onClick={() => resetResults()}
                            ></i>
                          </span>
                        </div>
                        <div className="delta-new-search-page-documentlist-scroll col-12">
                        {isLoading ?
                            <div className="text-center">
                              <img className="loading-img" src={logo} alt="loading"></img>
                            </div> : 
                            <>
                              {documentList.docs && documentList.docs.length > 0 ? (
                            <>
                              {/* <div className="cp_searchhead_modal-docs pt-3 pb-3"> */}
                              <Documents
                                docList={documentList.docs}
                                logDocClick={logDocClick}
                              />
                            </>
                          ) : (
                            <div className="d-flex justify-content-center delta-new-search-nodocument">
                            No documents found
                            </div>
                          )}
                            </>}
                          
                        </div>
                        {documentList.docs && documentList.docs.length > 0 && !isLoading? (
                          <div
                            className="text-center delta-new-Search-page-Pagination"
                          >
                            <Pagination
                              prevPageText="<"
                              nextPageText=">"
                              firstPageText="<<"
                              lastPageText=">>"
                              activePage={activePage}
                              itemsCountPerPage={itemsCountPerPage}
                              totalItemsCount={totalItemsCount}
                              pageRangeDisplayed={5}
                              onChange={handlePageChange}
                            />
                          </div>
                        ) : null}
                      </>
                    </Col>
                  )}
                  </>
                )}
                </Row>
            </Modal.Body>
      </Modal>
    </div>
     ):null}
          </>
  );
};

export default ChannelPartnerHeadSearchModal;